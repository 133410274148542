import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import moment from 'moment-timezone';
import './TransactionDetails.scss';
import { getUser } from '../../../../../../_helpers';
import Modal from 'react-bootstrap/Modal';
import jsPDF from 'jspdf';
import { balanceActions } from '../../../../../../_actions/balance.actions';
import { useDispatch, useSelector } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import Menu from '@mui/material/Menu';
import { selectAllBalanceLIstData } from '../../../../../../_reducers/balance.reducer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { orange } from '@mui/material/colors';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { MenuItem } from '@mui/material';
import { getEnvironment } from '../../../../../../_helpers/environmentProperties';
interface Props {
  // onSave: any;
  onClose: any;
  show: boolean;
  data: any;
  PageType: any;
}

interface objectType {
  id: string;
  value: string;
}
export const TransactionDetails = (props: Props) => {
  const [option, setOption] = useState<any>({
    id: '',
  });
  const dispatch = useDispatch();
  const [balance, setBalance] = useState<any>();
  const [otpData, setOtpData] = useState<any>();
  const [otpRender, setOtpRender] = useState(true);
  const [tagname, settagname] = useState<any>('');
  const [showModal, setShow] = useState(props.show);
  const [dataDisplay, setDataDisplay] = useState(false);
  const [Tagclass, setTagclass] = useState('');
  const [balanceRender, setBalanceRender] = useState(false);
  const [datadesplay, setDatadesplay] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(balanceActions.getPdf(props.data.reference) as any);
    setDatadesplay(true);
    setTimeout(() => {
      setBalanceRender(true);
    }, 3000);
  };
  const handleClose_twoo = () => {
    setAnchorEl(null);
  };
  const user = getUser()?.data ?? null;
  const balancelistData = useSelector((state) =>
    selectAllBalanceLIstData(state)
  );
  useEffect(() => {
    setOption(props.data);
    const data: any = props.data;
    const newData: any = {};
    setOption(data);
    let tagclass = '';
    if (data.Type == 'pay_in') {
      tagclass = 'tag_payin-tag';
      settagname('Pay In');
    } else if (data.Type == 'pay_out') {
      tagclass = 'tag_payout-tag';
      settagname('Pay Out');
    } else if (data.Type == 'fees') {
      tagclass = 'tag_fees-tag';
      settagname('Fees');
    }
    console.log('callback is: ',data.is_callback_sent);
    setTagclass(tagclass);
  }, [props.data]);
  useEffect(() => {
    if (balanceRender && balancelistData?.GET_PDF_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(balancelistData.GET_PDF_SUCCESS);
      setDatadesplay(false);
    }
  }, [balancelistData, balanceRender]);
  const onClose = () => {
    setShow(false);
    props.onClose(false);
  };
  const inputRef: any = React.useRef(null);
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const send_callback = async (id: any, ref:any, type: any) => {
    setLoading(true);
    const response = await axios
      .post(
        `${baseURL}${'/send-callback/'}${id}${'/'}${ref}${'/'}${type}`
      )
      .then((response) => {
        if (response.data.success == true) {
          toast.success(response.data.message, { duration: 8000 });
          console.log(' callback success');
          setLoading(false);
        } else {
          toast.error(response.data.message, { duration: 8000 });
          console.log(' callback failure');
          setLoading(false);
        }
      })
      setLoading(false);
  }
  const request_refund = async (reference: any, user_id: any, type: any) => {
    setLoading2(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: {"user_id": user_id, "reference": reference, "type": type}
    };
     const response = await axios
       .post(
         `${baseURL}${'/request-refund'}`, requestOptions
       )
       .then((response) => {
        console.log(response);
         if (response.data.success) {
          toast.success(response.data.message, { duration: 8000 });
           console.log(' refund success');
           setLoading2(false);
         } else {
          toast.error(response.data.message, { duration: 8000 });
           console.log(' refund failure');
           setLoading2(false);
         }
       })
   }
  return (
    <>
      <Modal
        show={showModal}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal trans-modal"
        id="afaFafffffFFff"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="trans-detail-containerX">
            <div className="trans-detail-container__table">
              {!!tagname ? (
                <div className="dflex b-bot">
                  <div className="left">Type</div>
                  <div className="right text-capitalize tag">
                    <span className={`tag_color ${Tagclass}`}>{tagname}</span>
                  </div>
                </div>
              ) : (
                ''
              )}
              {!!option.reference ? (
                <div className="dflex b-bot">
                  <div className="left">Reference</div>
                  <div className="right">{option.reference}</div>
                </div>
              ) : (
                ''
              )}
              {!!option.reference&&tagname != 'Pay Out' ? (
                <div className="dflex b-bot">
                  <div className="left">Customer Name</div>
                  {/* {!!props.PageType ? */}
                  <div className="right">
                    {`${!!option?.payment?.first_name ? option?.payment?.first_name:""}${" "}${!!option?.payment?.last_name ? option?.payment?.last_name : "-"}`}
                  </div>
                  {/* // : <div className="right">
                    //   {!!option.beneficiary_public_name
                    //     ? option?.beneficiary_public_name
                    //     : '-'}
                    // </div>} */}
                </div>
              ) : (
                ''
              )}
              {!!option.reference&&tagname != 'Pay Out' ? (
                <div className="dflex b-bot">
                  <div className="left">Email</div>
                  {/* {!!props.PageType ? */}
                  <div className="right">
                    {!!option?.payment?.email ? option?.payment?.email : "-"}
                  </div>
                  {/* //   :
                //   <div className="right">
                //   {!!option?.email ? option?.email : '-'}
                // </div>} */}
                </div>
              ) : (
                ''
              )}
              {!!option.payment_channel ? (
                <div className="dflex b-bot">
                  <div className="left">Payment Channel</div>
                  <div className="right">{option.payment_channel}</div>
                </div>
              ) : (
                ''
              )}
              {!!option.transaction_date ? (
                <div className="dflex b-bot">
                  <div className="left">Transaction Date</div>
                  <div className="right">
                    {moment
                      .tz(option.transaction_date, 'UTC')
                      .tz(moment.tz.guess())
                      .format('D MMMM YYYY, h:mm A')}
                  </div>
                </div>
              ) : (
                ''
              )}
              {!!option.transaction_amount ? (
                <div className="dflex b-bot">
                  <div className="left">Transaction Amount</div>
                  <div className="right">
                    {option.currency}{' '}
                    {!!option.transaction_amount
                      ? Number(
                        parseFloat(option.transaction_amount).toFixed(2)
                      ).toLocaleString('en', { minimumFractionDigits: 2 })
                      : 0}
                  </div>
                </div>
              ) : (
                ''
              )}
              {!!option.mobile && tagname == 'Pay In' && !!option.payee ? (
                <div className="dflex b-bot">
                  <div className="left">Sender Name</div>
                  <div className="right">{option.payee}</div>
                </div>
              ) : (
                ''
              )}
              {!!option.mobile && tagname == 'Pay In' ? (
                <div className="dflex b-bot">
                  <div className="left">Sender Number</div>
                  <div className="right">
                    {!!option.mobile ? option.mobile : ''}
                  </div>
                </div>
              ) : (
                ''
              )}
              {tagname == 'Pay Out' ? (
                !!option.payee ? (
                  <div className="dflex b-bot">
                    <div className="left">Payee Name</div>
                    <div className="right">{option.payee}</div>
                  </div>
                ) : (
                  ''
                )
              ) : !!option.beneficiary ? (
                <div className="dflex b-bot">
                  <div className="left">To</div>
                  <div className="right">
                    {tagname == 'Pay In' ? option.beneficiary : option.payee}
                  </div>
                </div>
              ) : (
                ''
              )}
              {option.payment_channel == 'M-Pesa Paybill' &&
                tagname == 'Pay Out' &&
                !!option.beneficiary_public_name ? (
                <div className="dflex b-bot">
                  <div className="left">Payee Name</div>
                  <div className="right">{option.beneficiary_public_name}</div>
                </div>
              ) : (
                ''
              )}
              {tagname == 'Fees' && !!option.beneficiary ? (
                <div className="dflex b-bot">
                  <div className="left">Payee Name</div>
                  <div className="right">{option.beneficiary}</div>
                </div>
              ) : (
                ''
              )}
              {option.payment_channel == 'M-Pesa Send Money' &&
                tagname == 'Pay Out' &&
                !!option.beneficiary_public_name ? (
                <div className="dflex b-bot">
                  <div className="left">Payee Name</div>
                  <div className="right">{option.beneficiary_public_name}</div>
                </div>
              ) : (
                ''
              )}
              {option.payment_channel == 'M-Pesa Till' &&
                tagname == 'Pay Out' &&
                !!option.beneficiary ? (
                <>
                  <div className="dflex b-bot">
                    <div className="left">Payee Name</div>
                    <div className="right">Merchant Till</div>
                  </div>
                </>
              ) : (
                ''
              )}
              {!!option.bank_name &&
                tagname == 'Pay Out' &&
                option.payment_channel == 'Pesalink' ? (
                <div className="dflex b-bot">
                  <div className="left">Bank Name</div>
                  <div className="right">{option.bank_name}</div>
                </div>
              ) : (
                ''
              )}
              {!!option.account_name &&
                tagname == 'Pay Out' &&
                option.payment_channel == 'Pesalink' ? (
                <div className="dflex b-bot">
                  <div className="left">Payee Name</div>
                  <div className="right">{option.account_name}</div>
                </div>
              ) : (
                ''
              )}
              {!!option.account_number &&
                tagname == 'Pay Out' &&
                option.payment_channel == 'Pesalink' ? (
                <div className="dflex b-bot">
                  <div className="left">Beneficiary Number</div>
                  <div className="right">{option.account_number}</div>
                </div>
              ) : (
                ''
              )}

              {!!option.mobile &&
                tagname == 'Pay Out' &&
                option.payment_channel == 'M-Pesa Send Money' ? (
                <>
                  <div className="dflex b-bot">
                    <div className="left">Beneficiary Number</div>
                    <div className="right">
                      {!!option.mobile ? option.mobile : ''}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {tagname == 'Pay Out' &&
                option.payment_channel == 'M-Pesa Paybill' &&
                !!option.paybill_number ? (
                <>
                  <div className="dflex b-bot">
                    <div className="left">Beneficiary Paybill Number</div>
                    <div className="right">
                      {!!option.paybill_number ? option.paybill_number : ''}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {tagname == 'Pay Out' &&
                option.payment_channel == 'M-Pesa Paybill' &&
                !!option.account_number ? (
                <>
                  <div className="dflex b-bot">
                    <div className="left">Account Number</div>
                    <div className="right">
                      {!!option.account_number ? option.account_number : ''}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {tagname == 'Pay Out' &&
                option.payment_channel == 'M-Pesa Till' &&
                !!option.till_number ? (
                <>
                  <div className="dflex b-bot">
                    <div className="left">Beneficiary Number</div>
                    <div className="right">
                      {!!option.till_number ? option.till_number : ''}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {!!option.mobile && tagname == 'Pay Out' && !!user.entity.name ? (
                <div className="dflex b-bot">
                  <div className="left">From</div>
                  <div className="right">{user.entity.name}</div>
                </div>
              ) : (
                ''
              )}
               {tagname == 'Pay Out' || tagname == 'Pay In' ?
                <div className="dflex b-bot">
                  <div className="left">Initiated From</div>
                  <div className="right">{!!option.payment_initiator  ? option.payment_initiator  : "-"}</div>
                </div> : ""}
              {tagname == 'Pay Out' || tagname == 'Pay In' ?
                <div className="dflex b-bot">
                  <div className="left">Third Party Reference 1</div>
                  <div className="right">{!!option.third_party_reference_1 ? option.third_party_reference_1 : "-"}</div>
                </div> : ""}
              {tagname == 'Pay Out' || tagname == 'Pay In' ?
                <div className="dflex b-bot">
                  <div className="left">Third Party Reference 2</div>
                  <div className="right">{!!option.third_party_reference_2 ? option.third_party_reference_2 : "-"}</div>
                </div> : ""}
              {!!option.invoice_no ? (
                <div className="dflex b-bot">
                  <div className="left">Invoice No</div>
                  <div
                    className="right"
                    // style={{
                    //   cursor: 'pointer',
                    //   textDecoration: 'underline',
                    //   fontWeight: 'bold',
                    // }}
                    // id="adaDdADddd"
                    // onClick={() => {
                    //   if (!!option.payable_id) {
                    //     window.location.href = `bills?id=${option.payable_id}`;
                    //   }
                    // }}
                  >
                    {option.invoice_no}
                  </div>
                </div>
              ) : (
                ''
              )}
{
  (tagname === 'Pay Out' || tagname === 'Pay In') && option.is_callback_sent === 1 ? (
    <div className="dflex b-bot">
      <div className="left">Callback Delivery Status</div>
      <div className="right">
        <p>
          <CheckCircleOutlinedIcon /> Delivered at{' '}
          {moment
            .tz(option.transaction_date, 'UTC')
            .tz(moment.tz.guess())
            .format('D MMMM YYYY, h:mm A')}
        </p>
      </div>
    </div>
  ) : (
    <div className="dflex b-bot">
      <div className="left">Callback Delivery Status</div>
      <div className="right">
        <p>
          <ErrorOutlineOutlinedIcon /> Failed
          <Button
            className="trans-detail-container__btn-row__green"
            style={{
              height: 'auto',
              width: 'fit-content',
              padding: '5px',
              fontSize: '12px',
              marginLeft: '10px',
            }}
            onClick={() => send_callback(option.id, option.reference, option.Type)}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Resend Callback
          </Button>
        </p>
      </div>
    </div>
  )}

                 
              
                {(tagname == 'Pay Out' || tagname == 'Pay In') && (option.status == 'success') && getEnvironment() === 'production'?
                <div className="dflex b-bot">
                  {/* <Tooltip title="Please note you can only refund the entire ammount"> */}
                      <div className="left">Request for Refund</div>
                  {/* </Tooltip> */}
                  <div className="right"> <Button
              className="trans-detail-container__btn-row__green"
              style={{height: "auto", width: "fit-content", padding: "5px", fontSize: "12px"}}
              onClick={() => {
                request_refund(option.reference, option.user_id, option.Type);
              }}
            >
               {loading2 == true && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )} 
              Refund
            </Button> </div>
                </div> : ""}
           
            </div>
          </div>
          <ThemeProvider theme={theme}>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose_twoo}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{ '& .MuiMenu-list': { padding: '0px' } }}
            >
              <div style={{ padding: '5px' }}>
                <div style={{ height: '27px' }}>
                  {!!balance && !!balance.data.path && datadesplay == false ? (
                    <a
                      style={{ textDecoration: 'none' }}
                      href={!balance ? '' : balance.data.url}
                      className="download_buttoun"
                      target="_blank"
                    >
                      Download PDF
                    </a>
                  ) : (
                    <a
                      style={{ textDecoration: 'none', cursor: 'no-drop' }}
                      className="download_buttoun"
                    >
                      Download PDF
                    </a>
                  )}
                </div>
                <div style={{ height: '27px' }}>
                  <WhatsappShareButton
                    disabled={
                      !!balance && !!balance.data.path && balanceRender == false
                        ? false
                        : true
                    }
                    url={`${process.env.REACT_APP_API_URL
                      }/get-transaction-pdf-link?hash=${!balance ? '' : balance.data.path
                      }${'\n'}To also use Niobi to easily track your finances and business payments, sign up here: https://app.niobi.co${'\n'}`}
                    onClick={handleClose_twoo}
                  >
                    Send via WhatsApp
                  </WhatsappShareButton>
                </div>
                <div style={{ height: '27px' }}>
                  <EmailShareButton
                    disabled={
                      !!balance && !!balance.data.path && balanceRender == false
                        ? false
                        : true
                    }
                    url={`To also use Niobi to easily track your finances and business payments, sign up here:https://app.niobi.co${'\n'}`}
                    body={`${process.env.REACT_APP_API_URL
                      }/get-transaction-pdf-link?hash=${!balance ? '' : balance.data.path
                      }${'\n'}`}
                  >
                    Send via Email
                  </EmailShareButton>
                </div>
              </div>
            </Menu>
          </ThemeProvider>
          <div className="trans-detail-container__btn-row">
            <Button
              className="trans-detail-container__btn-row__green"
              onClick={() => onClose()}
            >
              Close
            </Button>
            <Button
              className="trans-detail-container__btn-row__green"
              onClick={handleClick}
            >
              Share
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
