import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import moment from 'moment-timezone';
import "./TransactionDetails.scss";
import { selectAllPayablesListData } from "../../../../../../_reducers/payables.reducer";
import { useSelector } from "react-redux";
import { payablesActions } from "../../../../../../_actions/payables.actions";
import { getUser } from "../../../../../../_helpers";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import { FacebookShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import { balanceActions } from "../../../../../../_actions/balance.actions";
import { useDispatch } from "react-redux";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";

interface Props {
    // onSave: any;
    // onSave2: any;
    type: any;
    data: any;
    Close: any;
    shortcode: any;
}

interface objectType {
    id: string;
    value: string
}
export const TransactionDetails = (props: Props) => {
    const [option, setOption] = useState<any>({
        id: ''
    });
    const [balance, setBalance] = useState<any>();
    const [otpData, setOtpData] = useState<any>();
    const [otpRender, setOtpRender] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [datadesplay, setDatadesplay] = useState(true)
    const [balanceRender, setBalanceRender] = useState(false);
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    console.log(props?.shortcode, balance, dataList?.TRANSPAYLINK_PDF_SUCCESS, "props?.shortcode");

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        dispatch(paymentJourneyActions.TransLinkPdf(props?.data?.ref, props?.shortcode) as any);
        setDatadesplay(true)
        setTimeout(() => {
            setBalanceRender(true)
        }, 3000)
    };
    const handleClose_twoo = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (balanceRender && dataList?.TRANSPAYLINK_PDF_SUCCESS !== undefined) {
            setBalanceRender(false);
            setBalance(dataList.TRANSPAYLINK_PDF_SUCCESS);
            setDatadesplay(false)
        }
    }, [dataList, balanceRender]);
    const navigate = useNavigate();
    console.log(props, "propsprops");

    const user = getUser()?.data ?? null;
    console.log(option, "optionoption");


    useEffect(() => {
        setOption(props.data);

        const data: any = props.data;
        const dataMock: any = {

            "id": "d454502b-b05e-4b93-abc3-6c2761f56120",
            "reference": null,
            "status": "000001",
            "message": "Request received successfully",
            "receiptNumber": null,
            "commandId": "BillPay",
            "serviceProviderId": "MPESA",
            "datetimeCreated": "2023-02-23 05:39:27.056 +0000",
            "datetimeLastModified": "2023-02-23 05:39:27.056 +0000",
            "datetimeCompleted": null,
            "requestParameters": [
                {
                    "id": "amount",
                    "value": "10",
                    "label": "Amount"
                },
                {
                    "id": "businessNumber",
                    "value": "123456",
                    "label": "Paybill"
                },
                {
                    "id": "accountNumber",
                    "value": "123456",
                    "label": "Customer reference"
                },
                {
                    "id": "narration",
                    "value": "Withdrawal",
                    "label": "More Info"
                }
            ],
            "referenceParameters": [
                {
                    "id": "resultUrl",
                    "value": "https://wallet.niobi.co/api/paybill/results",
                    "label": "Hook"
                }
            ],
            "resultParameters": null

        };
        const newData: any = {}

        // data.requestParameters.map((e: objectType) => {
        //     data[e.id] = e.value;
        // })
        console.log(data, 'newDatta');

        setOption(data);


    }, [props.data]);

    const onSave = () => {
        // props.onSave();
    }
    const onSave2 = () => {
        // props.onSave2();
    }
    console.log('ooooooooooooooooooooooootp: ', props);
    function hidePhoneNo(phoneNo: any) {
        // replace the characters with asterisks
        return phoneNo.slice(0, 6) + "*****" + phoneNo.slice(12);
    }
    function hidePaybillNo(paybillNo: any) {
        // replace the characters with asterisks
        return paybillNo.slice(0, 2) + "*****" + paybillNo.slice(paybillNo.length - 1);
    }
    function hideAccountNo(accountNo: any) {
        // replace the characters with asterisks
        return accountNo.slice(0, 2) + "*****" + accountNo.slice(accountNo.length - 2);
    }

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <div className='trans-detail-container' style={{ marginTop: "25px" }}>
            <div className='trans-detail-container__heading'>
                Transaction Details
            </div>

            <div className='trans-detail-container__table'>
                <div className='dflex b-bot'>
                    <div className='left'>Transaction Status</div>
                    <div className='right'>Success</div>
                </div>
                {
                    (!!option.type) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Type</div>
                            {option.type == 'pay_in' || option.type == 'Pay in' ? <div id='typestyle2'>{option.type}</div> :
                                option.type == 'pay_out' || option.type == 'Pay Out' ?
                                    <div id='typestyle'>{option.type}</div> : <div id='typestyle3'>{option.type}</div>}
                        </div> : ""
                }
                {
                    (!!option.ref) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Reference</div>
                            <div className='right'>{option.ref}</div>
                        </div> : ""
                }
                {
                    (!!option.payment_channel) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Payment Channel</div>
                            <div className='right'>{option.payment_channel}</div>
                        </div> : ""
                }
                {
                    (!!option.updated_at) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Transaction Date</  div>
                            <div className='right'>{moment.tz(option.updated_at, "UTC").tz(moment.tz.guess()).format('D MMMM YYYY, h:mm A')}</div>
                        </div> : ""
                }
                {
                    (!!option.credit) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Transaction Amount</div>
                            <div className='right'>{option.currency} {!!option.credit ?
                                Number((parseFloat((option.credit)) / 100).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : 0}</div>
                        </div> : ""
                }
                {!!option.mobile && option.type == 'Pay in' && !!option.payee ?
                    <div className='dflex b-bot'>
                        <div className='left'>Sender Name</div>
                        <div className='right'>{option.payee}</div>
                    </div> : ""}
                {/* {(!!user && !!option) ? option.type == 'pay_in' || option.type == 'Pay In' ? !!option.payee ? <div className='dflex b-bot'>
                    <div className='left'>Sender Name</div>
                    <div className='right'>{option.payee}</div>
                </div> : "" :
                    <div className='dflex b-bot'>
                        <div className='left'>From</div>
                        <div className='right'>{user.entity.name}</div>
                    </div> : ""
                } */}
                {!!option.mobile && option.type == 'Pay in' ?
                    <div className='dflex b-bot'>
                        <div className='left'>Sender Number</div>
                        <div className='right'>
                            {!!option.mobile ? option.mobile : ""}</div>
                    </div>
                    : ""
                }
                {/* {!!option && option.type == 'pay_out' || !!option && option.type == 'Pay Out' ?
                    (!!option.payee) ?
                        <div className='dflex b-bot'>
                            <div className='left'>{option.type == 'pay_out' ? "Payee Name" : "company name"}</div>
                            <div className='right'>{option.payee}</div>
                        </div> : "" : <div className='dflex b-bot'>
                        <div className='left'>{option.type == 'pay_out' ? "Payee Name" : "company name"}</div>
                        <div className='right'>{option.type == 'pay_in' || option.type == 'Pay In' ? option.beneficiary : option.payee}</div>
                    </div>
                } */}
                {option.type == 'Pay out' ?
                    (!!option.payee) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Name</div>
                            <div className='right'>{option.payee}</div>
                        </div> : "" : (!!option.beneficiary) ? <div className='dflex b-bot'>
                            <div className='left'>To</div>
                            <div className='right'>{option.type == 'Pay in' ? option.beneficiary : option.payee}</div>
                        </div> : ""
                }
                {/* {option.beneficiary && option.type == 'Pay out' ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary name</div>
                            <div className='right'>{!!option ? option.payee : ""}</div>
                        </div>
                    </> : ""
                } */}
                {option.payment_channel == "M-Pesa Paybill" && option.type == 'Pay Out' && !!option.beneficiary_public_name ?
                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>{option.beneficiary_public_name}</div>
                    </div> : ""}
                {option.payment_channel == "M-Pesa Send Money" && option.type == 'Pay Out' && !!option.beneficiary_public_name ?
                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>{option.beneficiary_public_name}</div>
                    </div> : ""}
                {option.type == 'Fees' && !!option.beneficiary ?
                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>{option.beneficiary}</div>
                    </div> : ""}
                {option.payment_channel == "M-Pesa Till" && option.type == 'Pay Out' && !!option.beneficiary ? <>

                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>Merchant Till</div>
                    </div>
                </> : ""}
                {/* {tagname == 'Pay Out' ?
                                (!!option.payee) ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Payee Name</div>
                                        <div className='right'>{option.payee}</div>
                                    </div> : "" : (!!option.beneficiary) ? <div className='dflex b-bot'>
                                        <div className='left'>To</div>
                                        <div className='right'>{tagname == 'Pay In' ? option.beneficiary : option.payee}</div>
                                    </div> : ""
                            } */}
                {!!option.bank_name && option.type == 'Pay out' && option.payment_channel == "Pesalink" ?
                    <div className='dflex b-bot'>
                        <div className='left'>Bank Name</div>
                        <div className='right'>{option.bank_name}</div>
                    </div> : ""}
                {!!option.account_name && option.type == 'Pay out' && option.payment_channel == "Pesalink" ?
                    <div className='dflex b-bot'>
                        <div className='left'>Beneficiary Name</div>
                        <div className='right'>{option.account_name}</div>
                    </div> : ""}
                {!!option.account_number && option.type == 'Pay out' && option.payment_channel == "Pesalink" ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Number</div>
                            <div className='right'>{option.account_number}</div>
                        </div></> : ""
                }
                {option.type == 'pay_out' && option.payment_channel == 'M-Pesa Send Money' && !!option.till_number || option.type == 'Pay out' && option.payment_channel == 'M-Pesa Send Money' && !!option.mobile ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Number</div>
                            <div className='right'>{!!option.mobile ? option.mobile : ""}</div>
                        </div></> : ""}
                {option.type == 'pay_out' && option.payment_channel == 'M-Pesa Paybill' && !!option.paybill_number || option.type == 'Pay out' && option.payment_channel == 'M-Pesa Paybill' && !!option.paybill_number ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Paybill Number</div>
                            <div className='right'>{!!option.paybill_number ? option.paybill_number : ""}</div>
                        </div></> : ""}
                {option.type == 'pay_out' && option.payment_channel == 'M-Pesa Paybill' && !!option.account_number || option.type == 'Pay out' && option.payment_channel == 'M-Pesa Paybill' && !!option.account_number ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Account Number</div>
                            <div className='right'>{!!option.account_number ? option.account_number : ""}</div>
                        </div></> : ""}
                {option.type == 'pay_out' && option.payment_channel == 'M-Pesa Till' && !!option.till_number || option.type == 'Pay out' && option.payment_channel == 'M-Pesa Till' && !!option.till_number ?
                    <>
                        <div className='dflex b-bot'>
                            <div className='left'>Beneficiary Number</div>
                            <div className='right'>{!!option.till_number ? option.till_number : ""}</div>
                        </div></> : ""}
                {!!option.mobile && option.type == 'Pay out' && !!user.entity.name ?
                    <div className='dflex b-bot'>
                        <div className='left'>From</div>
                        <div className='right'>{user.entity.name}</div>
                    </div> : ""}


                {
                    (!!option.invoice_no) ?
                        <div className='dflex b-bot'>
                            <div className='left'>Invoice No</div>
                            <div className='right' 
                            // style={{cursor:"pointer",textDecoration:"underline",fontWeight:"bold"}} 
                            // onClick={() => {
                            //                 if (!!option.payable_id) {
                            //                     window.location.href = `bills?id=${option.payable_id}`
                            //                 }
                            //             }}
                                        >{option.invoice_no}</div>
                        </div> : ""
                }
            </div>
            <ThemeProvider theme={theme}>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose_twoo}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ "& .MuiMenu-list": { padding: "0px" } }}
                >
                    <div style={{ padding: "5px" }}>
                        <div style={{ height: "27px" }}>
                            {!!balance && !!balance.data.path && datadesplay == false ?
                                <a style={{ textDecoration: "none" }}
                                    href={!balance ? "" : balance.data.url}
                                    className='download_buttoun' target="_blank">Download PDF</a> :
                                <a style={{ textDecoration: "none", cursor: "no-drop" }}
                                    //  href={!balance ? "" : balance.data.url}
                                    className='download_buttoun'>Download PDF</a>}
                        </div>
                        <div style={{ height: "27px" }}>
                            <WhatsappShareButton
                                disabled={!!balance && !!balance.data.path && balanceRender == false ? false : true}
                                // url={`To also use Niobi to easily track your finances and business payments, sign up here: https://app.niobi.co${'\n'} ${process.env.REACT_APP_API_URL}/get-topup-pdf-link?hash=${!balance ? "" : balance.data.path}`}
                                url={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${!balance ? "" : balance.data.path}${'\n'}To also use Niobi to easily track your finances and business payments, sign up here: https://app.niobi.co${'\n'}`}
                                onClick={handleClose_twoo}
                            >
                                {/* <WhatsAppIcon /> */}
                                Send via WhatsApp
                            </WhatsappShareButton>
                        </div>
                        <div style={{ height: "27px" }}>
                            <EmailShareButton
                                disabled={!!balance && !!balance.data.path && balanceRender == false ? false : true}
                                // onClick={handleClose_twoo}
                                url={`To also use Niobi to easily track your finances and business payments, sign up here:https://app.niobi.co${'\n'}`}
                                body={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${!balance ? "" : balance.data.path}${'\n'}`}
                            >
                                Send via Email
                            </EmailShareButton>

                        </div>
                    </div>
                    {/* <MenuItem onClick={handleClose_twoo}>
                            Profile</MenuItem>
                        <MenuItem onClick={handleClose_twoo}>My account</MenuItem>
                        <MenuItem onClick={handleClose_twoo}>Logout</MenuItem> */}
                </Menu>
            </ThemeProvider>
            <div className="trans-detail-container__btn-row">

                <Button className='trans-detail-container__btn-row__green' onClick={() =>{
                    props.Close()
                     window.location.reload();
                }}>Close</Button>
                <Button className='trans-detail-container__btn-row__green'
                    onClick={handleClick}
                // onClick={() => printDocument()}
                >Share</Button>
                {/* <Button className='trans-detail-container__btn-row__green' onClick={() => onSave2()}>Send Payment Details</Button> */}

            </div>
            {/* <div className='trans-detail-container__btn-row'>
            <Button className='trans-detail-container__btn-row__green' onClick={() => onSave()}>Return to account</Button>
            </div> */}
        </div>
    )
}
