import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './Balance.scss';
import { PayableGrid } from './components/PayableGrid/PayableGrid';
import { TopupModal } from './components/topup-modal/Topup';
import { TopupModal_two } from './components/topup-modal/Topup_two';
import TopupDetail from './components/TopupDetail/TopupDetail';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUser, isUserAuthenticated, authHeader } from '../../../../_helpers';
import { selectAllData } from '../../../../_reducers/dashboard.reducer';
import { selectAllBalanceLIstData } from '../../../../_reducers/balance.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../../../_actions';
import moment from 'moment';
import DatePickerll from 'react-datepicker';
import BalanceTable from './BalanceTable';
import { TransactionDetails } from './components/TransactionDetails/TransactionDetails';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Virefily_account } from './components/TransactionDetails/virefiy_account';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { selectAllCounterpartsListData } from '../../../../_reducers/counterparts.reducer';
import { counterpartsActions } from '../../../../_actions/counterparts.actions';
import Menu from '@mui/material/Menu';
import Pending_Transactions_popup from '../../../app/Layout/Dashboard/components/Pending_Transactions_popup/Pending_Transactions_popup';
import Topup_balk_data from './components/topup-modal/csv_balk';
import amplitude from 'amplitude-js';
import { getProperties } from '../../../../_helpers';
import SelectPopup from './components/download/selectPopup';
import Popup from './components/download/popup';
import Select_source_account from './components/Select_source_account';
import SelectMethod from './components/topup-modal/selectMethod';
import posthog from 'posthog-js';
import ChooseCurrency from './components/Choose_currency/ChooseCurrency';
import { paymentJourneyActions } from '../../../../_actions/paymentJourney.actions';
import { selectPaymentJourneyData } from '../../../../_reducers/paymentJourney.reducer';
import { getEnvironment } from '../../../../_helpers/environmentProperties';
import BalanceCard from './BalanceCard';
// import { SkeletonCom, SkeletonDashboardV3 } from './components/Skeleton';
import { Box, MenuItem, Select, Tooltip } from '@mui/material';
import { SkeletonCom, SkeletonDashboardV3 } from '../Skeleton/Skeleton';
const SearchIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
      stroke="#111111"
      stroke-width="1.1875"
      stroke-miterlimit="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const Balance = () => {
  const user = getUser()?.data ?? '';
  const navigate = useNavigate();
  const [balance, setBalance] = useState<any>([]);
  const [balanceList, setBalanceList] = useState<any>([]);
  const [balanceListtwo, setBalanceListtwo] = useState<any>([]);
  const [datatabal, setDatatebal] = useState<any>([]);
  const [render, setRender] = useState(true);
  const [balanceRender, setBalanceRender] = useState(false);
  const [balanceListRender, setBalanceListRender] = useState(true);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [loader, setLoader] = useState(true);
  const [csvUrl, setCsvUrl] = useState<any>([]);
  const [pdfUrl, setPdfUrl] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const data = useSelector((state) => selectAllData(state));
  const [tagsList, setTagsList] = useState<any>([]);
  const [tagsListRender, setTagsListRender] = useState(true);
const[selecttype,setSelectType]=useState('')
  const [verefire_data, setVirefay_data] = useState(false);
  const [select_amount, setSelect_amount] = useState('');
  const [SelectCurrency, setSelectCurrency] = useState<any>(select_amount);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl_unsettled, setAnchorEl_unsettled] =
    React.useState<null | HTMLElement>(null);
  const [currencySelect, setCurrencySelect] = React.useState(false);

  const open = Boolean(anchorEl);
  const open_unsettled = Boolean(anchorEl_unsettled);
  console.log(SelectCurrency, 'select_amountselect_amount');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, Type: any) => {
    setAnchorEl(event.currentTarget);
    setHedingPopup(Type)
  };
  const handleClose_popup = () => {
    setAnchorEl(null);
  };
  const handleClick_unsettled = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl_unsettled(event.currentTarget);
  };
  const handleClose_unsettled = () => {
    setAnchorEl_unsettled(null);
  };
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const properties = getProperties();
  useEffect(() => {
    console.log('useEffectCalled Top', tagsListRender, counterpartsListData);
    if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
      let options: any = [];
      if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
        counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
          options.push({
            id: item.id,
            name: item.name,
          });
        });
      }
      setTagsListRender(false);
      setTagsList(options);
    }
  }, [counterpartsListData, tagsListRender]);
  const balancelistData = useSelector((state) =>
    selectAllBalanceLIstData(state)
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
      console.log(data, 'data.GET_DASHBOARD_BALANCE_SUCCESS');
    }
  }, [data, balanceRender]);
  console.log(csvUrl, 'csvUrlcsvUrl');

  useEffect(() => {
    if (balanceListRender && balancelistData?.GETBALANCE_REQUESTTWO == false) {
      console.log('i m inside');
      setBalanceListRender(false);
      setBalanceListtwo(
        !!balancelistData.GETBALANCE_SUCCESSTWO
          ? balancelistData.GETBALANCE_SUCCESSTWO.sort(
            (a: any, b: any) => b.id - a.id
          )
          : balancelistData.GETBALANCE_SUCCESSTWO
      );
    }
  }, [balancelistData, balanceListRender]);
  useEffect(() => {
    if (user && isUserAuthenticated()) {
    } else if (!user) {
      navigate('/login');
    }
  }, [user]);
  const [showTopup, setShowTopup] = useState(false);
  const [showTopup_two, setShowTopup_two] = useState(false);
  const [topUp, setTopup] = useState(false);
  const [showBalanceGrid, setShowBalanceGrid] = useState(true);
  const [topUpMethod, setTopupMethod] = useState(false);
  const [paymentGrid, setPaymentGrid] = useState(false);
  const [search, setSearch] = React.useState('');
  const [pageneshn, setPagelist] = useState(1);
  const [remove, setRemovedata] = useState(10);
  const [showTransMoodal, setshowTransMoodal] = React.useState(false);
  const [requestRender, setrequestRender] = useState(false);
  const [responseRender, setresponseRender] = useState(false);
  const [transData, settransData] = React.useState({});
  const [loderinapi, setLoderapi] = React.useState(false);
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(10);
  const [openlist, setOpenlist] = React.useState(false);
  const [categ, setCateg] = React.useState('');
  const [topup_balk_data, setTopup_balk_data] = React.useState(false);
  const [counterpartsList_two, setCounterpartsList_two] = useState<any>();
  const [counterpartsListRender, setCounterpartsListRender] = useState(true);
  const [select_data, setSelect_data] = useState('');
  const [data_type, setType_data] = useState('');
  const [recent, setRecent] = useState<any>();
  const [Dounload_popup, setDounload_popup] = useState(false);
  const [Dounload_popup_pdf, setDounload_popup_pdf] = useState(false);
  const [type_select, setType_select] = useState<any>([
    {
      name: 'Pay Out',
      id: 'pay_out',
    },
    {
      name: 'Pay In',
      id: 'pay_in',
    },
    {
      name: 'Fees',
      id: 'fees',
    },
  ]);
  const [cardTypeselect, setCardTypeSelect] = useState("ALL")
  const [setlementType, setSetlementType] = useState<any>([
    {
      name: 'Settled',
      id: 'settled',
    },
    {
      name: 'Unsettled',
      id: 'unsettled',
    },
  ]);
  const [transactionStatus, setTransactionStatus] = useState<any>([
    {
      name: 'Success',
      id: 'success',
    },
    {
      name: 'Failed',
      id: 'failed',
    },
    {
      name: 'Pending',
      id: 'pending',
    },
  ]);
  const [typeButton, setTypeButton] = useState("")
  // const paymentChannels = [
  //   {
  //     name: 'M-Pesa Paybill',
  //     id: 'M-Pesa Paybill',
  //   },
  //   {
  //     name: 'M-Pesa Till',
  //     id: 'M-Pesa Till',
  //   },
  //   {
  //     name: 'Pesalink',
  //     id: 'Pesalink',
  //   },
  //   {
  //     name: 'Mtn',
  //     id: 'Mtn',
  //   },
  //   {
  //     name: 'Airtel',
  //     id: 'Airtel',
  //   },
  // ];
  const [selectMethod, setSelectMethod] = useState(false);
  const [select_popup, setSelect_popup] = useState('');
  const [countryList, setCountrtList] = useState([]);
  const [startgetapi, setStartgetapi] = useState(true);
  const [hedingPopup, setHedingPopup] = useState("")
  const [settlement, setSettlement] = useState("")
  const [paymentChannels, setPaymentChannels] = useState<any>([])
  const [renderDataChannels, setRenderDataChannels] = useState(true)
  const [selectCurrencyPopup, setSelectCurrencyPopup] = useState<any>('KES')
  const [Balanceloader, setBalanceloader] = useState(false)

  const today = new Date();
  console.log(select_popup, 'select_popup');
  const dataList = useSelector((state) => selectPaymentJourneyData(state));

  const baseURL =
    getEnvironment() === 'production'
      ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_STAGING_API_URL;

  useEffect(() => {
    if (renderDataChannels && data?.GET_UNIFIED_PAYMENT_CHANNELS_SUCCESS !== undefined) {
      setRenderDataChannels(false)
      setPaymentChannels(data?.GET_UNIFIED_PAYMENT_CHANNELS_SUCCESS)
      console.log(data?.GET_UNIFIED_PAYMENT_CHANNELS_SUCCESS, "dsfdszszdff");

    }
  }, [renderDataChannels, data])
  useEffect(() => {
    if (
      counterpartsListRender &&
      counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined
    ) {
      setCounterpartsListRender(false);
      setCounterpartsList_two(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
    }
  }, [counterpartsListData, counterpartsListRender]);

  const getCsvurl = async (datalist: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    };
    const stateDateApi = !!datalist.startdate
      ? moment(datalist.startdate).format('YYYY-MM-DD')
      : '';
    const endDateApi = !!datalist.enddate
      ? moment(datalist.enddate).format('YYYY-MM-DD')
      : '';
    const response = await axios
      .get(
        `${baseURL}/unified-export-csv?search=${datalist.search}&start-date=${stateDateApi}&end-date=${endDateApi}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true) {
          setCsvUrl(response.data);
          setPdfUrl(csvUrl.pdf);
        } else {
        }
      });
  };

  const getBalanceList = async (datalist: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    };
    const stateDateApi = !!datalist.startdate
      ? moment(datalist.startdate).format('YYYY-MM-DD')
      : '';
    const endDateApi = !!datalist.enddate
      ? moment(datalist.enddate).format('YYYY-MM-DD')
      : '';
    const category = !!datalist.category ? datalist.category : '';

    var pagelimit = 10;
    const searchString = `?search=${datalist.search}&start-date=${stateDateApi}&end-date=${endDateApi}&payee=${datalist.payee}&type=${datalist.type}&page=${datalist.pagedata}&limit=${pagelimit}&category=${category}`;
    setLoderapi(true);
    const response = await axios
      .get(
        `${baseURL}${'/get-unified-payments'
        }?search=${datalist.search
        }&category=${''}&start-date=${stateDateApi}&end-date=${endDateApi}&payee=${''
        }&settlement=${datalist.settlement}&currency=${datalist.select_amount}&type=${datalist.type}&status=${datalist.payee
        }&payment_channel=${category}&page=${datalist.pagedata
        }&limit=${pagelimit}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true && response.data.data.data.length > 0) {
          setDatatebal(response.data.data.data);
          setTotalCount(response.data.data.total);
          setTimeout(() => {
            setLoderapi(false);
          }, 2000);
        } else {
          setDatatebal([])
          setTimeout(() => {
            setLoderapi(false);
          }, 6000);
        }
      })

  };
  // useEffect(() => {
  //   if (isUserAuthenticated() && render && user?.role_name !== 'Developer') {
  //     if(!!select_amount){
  //     var serdata: any = {
  //       search: search,
  //       startdate: startDate,
  //       enddate: endDate,
  //       payee: select_data,
  //       type: data_type,
  //       pagedata: pageneshn,
  //       category: categ,
  //       select_amount: select_amount,
  //       settlement: settlement
  //     };
  //     getBalanceList(serdata);
  //     getCsvurl(serdata);
  //   }

  //   }
  // }, [
  //   render,
  //   isUserAuthenticated,
  //   startDate,
  //   endDate,
  //   select_data,
  //   data_type,
  //   pageneshn,

  // ]);
  useEffect(() => {
    if (render && !!select_amount) {
      setRender(false);
      dispatch(paymentJourneyActions.getCountriesBalances() as any);
      dispatch(dashboardActions.getBalance(`/wallet/get-currency-balance/${select_amount}`, setBalanceRender, setBalanceloader) as any);
      dispatch(
        dashboardActions.getunifiedPaymentChannels(select_amount) as any
      );
      dispatch(dashboardActions.UpdateUnifiedPayments(select_amount) as any);
      dispatch(counterpartsActions.getAllCounterparts() as any);
      dispatch(counterpartsActions.getAllTags() as any);
      setBalanceListRender(true);
      setrequestRender(true);
      setLoader(true);
    } else {
      dispatch(paymentJourneyActions.getCountriesBalances() as any);
    }
  }, [render, select_amount]);
  useEffect(() => {
    if (startgetapi && dataList?.GETCOUNTRIES_BALANCES_SUCCESS !== undefined) {
      setCountrtList(dataList?.GETCOUNTRIES_BALANCES_SUCCESS);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  useEffect(() => {
    if (requestRender && balancelistData?.GETBALANCE_REQUEST == true) {
      setLoader(balancelistData.GETBALANCE_REQUEST);
      setresponseRender(true);
      setrequestRender(false);
    }
  }, [balancelistData, requestRender]);

  useEffect(() => {
    if (responseRender && balancelistData?.GETBALANCE_REQUEST == false) {
      if (balancelistData.GETBALANCE_SUCCESS.data != balanceList) {
        setLoader(false);
        setrequestRender(true);
        setresponseRender(false);
        setBalanceList(
          !!balancelistData.GETBALANCE_SUCCESS.data
            ? balancelistData.GETBALANCE_SUCCESS.data
            : []
        );
      }
    }
  }, [balancelistData, responseRender]);

  useEffect(() => {
    if (!!state) {
      const { type, color } = state; // Read values passed on state
      if (!!type) {
        console.log(type, 'typeinside ');
        if (type == 'topup') {
          setShowTopup(true);

          console.log('showTopup', showTopup);
        }
      }
    }
  }, [state]);
  useEffect(() => {
    const popup = searchParams.get('popup');
    if (!!popup) {
      setPaymentGrid(true);
      setShowBalanceGrid(false);
      setTopup(false);
    } else {
    }
  }, [searchParams]);
  useEffect(() => {
    const Currancy = searchParams.get('currency');
    if (!!Currancy) {
      setSelect_amount(Currancy)
      setSelectCurrencyPopup(Currancy)
      // setSelectCurrency(Currancy)
    } else {
      setSelect_amount("")
      setSelectCurrencyPopup("")
      // setSelectCurrency("")
    }
  }, [searchParams])
  useEffect(() => {
    setSelectCurrency(select_amount)
  }, [select_amount])
  function doneTyping() {
    var serdata: any = {
      search: search,
      startdate: startDate,
      enddate: endDate,
      payee: select_data,
      type: data_type,
      pagedata: pageneshn,
      category: categ,
      select_amount: select_amount,
      settlement: settlement
    };
    getBalanceList(serdata);
    setBalanceListRender(true);
    setrequestRender(true);
    setLoader(true);
  }

  useEffect(() => {
    if (!!select_amount) {
      const timeoutId = setTimeout(() => {
        doneTyping();
        console.log('usereffect testing');
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [search, select_amount]);

  console.log(user, 'userWWW');
  const theme = createTheme({
    palette: {
      primary: {
        main: '#000',
      },
    },
  });
  const onSavecurrancy = (data: any) => {
    var serdata: any = {
      search: search,
      startdate: startDate,
      enddate: endDate,
      payee: select_data,
      type: data_type,
      pagedata: pageneshn,
      category: categ,
      select_amount: data,
      settlement: settlement,
    };
    dispatch(
      dashboardActions.getBalance(`/wallet/get-currency-balance/${data}`, setBalanceRender, setBalanceloader) as any
    );
    dispatch(dashboardActions.UpdateUnifiedPayments(data) as any);

    dispatch(
      dashboardActions.getunifiedPaymentChannels(data) as any
    );
    getBalanceList(serdata);
    getCsvurl(serdata);
    setTimeout(() => {
      // setBalanceRender(true);
      setRenderDataChannels(true)
      // setLoderapi(true);
    }, 6500);
  };

  useEffect(() => {
    if (!!select_amount) {
      var serdata: any = {
        search: search,
        startdate: startDate,
        enddate: endDate,
        payee: select_data,
        type: data_type,
        pagedata: pageneshn,
        category: categ,
        select_amount: select_amount,
        settlement: settlement
      };
      getBalanceList(serdata);
      getCsvurl(serdata);
      // setTimeout(() => {
      //   setBalanceRender(true);
      // }, 3500);
    }
  }, [startDate, endDate, pageneshn, select_amount]);

  const handleDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  console.log(remove < totalCount, "remove > totalCount");

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <div
          className="document-verified"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '598px',
            height: '250px',
            flexDirection: 'column',
            background: '#FFFFFF',
            boxShadow: '0px 4px 12px rgba(117, 114, 114, 0.25)',
            borderRadius: '8px',
            padding: '48px 64px',
            margin: '0px 0px',
            textAlign: 'center',
          }}
        >
          <div className="document-verified__desc">
            <p style={{ paddingTop: '17px', fontSize: '14px' }}>
              {csvUrl?.message}
            </p>
            <br />
          </div>
          <Button
            id="Go_back"
            className="document-verified__btn"
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </div>
      </Modal>

      {showBalanceGrid && (
        <>
          <div className="balance">
            <div className="balance__header">
              <div className="balance__header__name">My Accounts</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <>
                  {!!select_amount ? (<div
                    className="header"
                    style={{ fontSize: '14px', paddingRight: "15px" }}
                    id="totalBalance_header"
                  >
                    Select currency{' '}

                  </div>) : ("")}
                  {!!select_amount ? (
                    // <Button
                    //   style={{ marginRight: '10px' }}
                    //   className="balance__white-btn"
                    //   onClick={() => {
                    //     setSelect_amount('');
                    //     setCardTypeSelect("ALL")
                    //   }}
                    // >
                    //   Change Currency{' '}
                    // </Button>
                    <ThemeProvider theme={theme}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="selectBox"
                        style={{ marginRight: "12px" }}
                        value={selectCurrencyPopup}
                        // sx={{
                        //   p: {
                        //     display: "none"
                        //   }
                        // }}
                        onChange={(e: any) => {
                          setSelectCurrencyPopup(e.target.value)
                          setSelectCurrency(e.target.value)
                          setSelect_amount(e.target.value)
                          onSavecurrancy(e.target.value)
                          navigate(`/balance?currency=${e.target.value}`);
                        }}
                      >
                        {Array.isArray(countryList) && countryList.map((item: any, index) => {
                          return (
                            <MenuItem
                              value={item.currency}
                              key={index}>
                              <Box className="MenuItemBox" >
                                <img
                                  loading="lazy"
                                  width="25"
                                  src={item.flag}
                                  alt=""
                                />
                                <p>
                                  {item.currency}
                                </p>
                              </Box>
                            </MenuItem>

                          )
                        })}
                      </Select>
                    </ThemeProvider>
                  ) : (
                    ''
                  )}

                  {!!user &&
                    user.entity.status == 'UNVERIFIED' &&
                    !!select_amount ? (
                    <Button
                      className="balance__white-btn"
                      onClick={() => {
                        setVirefay_data(true);
                      }}
                    >
                      Receive payments{' '}
                    </Button>
                  ) : (!!user &&
                    !!user.role_name &&
                    user.role_name == 'Accountant') ||
                    user.role_name == 'Admin' ||
                    (user.role_name == 'Director' && !!select_amount) ? (
                    <Button
                      className="balance__white-btn"
                      onClick={() => {
                        const event = 'New Top Up Via Balance';
                        const eventProperties = {
                          userId:
                            !!properties && !!properties.user_id
                              ? properties.user_id
                              : null,
                          userName:
                            !!properties && !!properties.name
                              ? properties.name
                              : null,
                          email:
                            !!properties && !!properties.email
                              ? properties.email
                              : null,
                          pageUrl: window.location.href,
                        };

                        amplitude
                          .getInstance()
                          .logEvent(event, eventProperties);
                        posthog.capture(event, eventProperties);
                        // setShowTopup(true);
                        setCurrencySelect(true)
                        // setSelectType
                        setTypeButton("Receive payments")
                      }}
                    >
                      Receive payments{' '}
                    </Button>
                  ) : !!select_amount ? (
                    <Button
                      className="balance__white-btn"
                      style={{ cursor: 'not-allowed' }}
                    >
                      Receive payments{' '}
                    </Button>
                  ) : (
                    <Box sx={{ height: "44px" }}></Box>
                  )}
                  {!!user &&
                    user.entity.status == 'UNVERIFIED' &&
                    !!select_amount ? (
                    <Button
                      className="balance__save-btn"
                      onClick={() => {
                        setVirefay_data(true);
                      }}
                    >
                      Send payments
                    </Button>
                  ) : !!user &&
                    !!user.role_name &&
                    user.role_name == 'Developer' ? (
                    <Button
                      className="balance__save-btn"
                      style={{ cursor: 'no-drop' }}
                    >
                      Send payments
                    </Button>
                  ) : !!select_amount ? (
                    <Button
                      className="balance__save-btn"
                      onClick={() => {
                        setCurrencySelect(true)
                        setTypeButton("Send payments")
                      }}
                    >
                      Send payments
                    </Button>
                  ) : (
                    <Box sx={{ height: "44px" }}></Box>
                  )}
                </>
              </div>
            </div>
            <ThemeProvider theme={theme}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose_popup}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Pending_Transactions_popup
                  Heading={'Pending Transactions'}
                  transactions={balance[0]?.transactions?.niobi_unified_transactions
                  }
                />
              </Menu>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl_unsettled}
                open={open_unsettled}
                onClose={handleClose_unsettled}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Pending_Transactions_popup
                  Heading={'Unsettled Transactions'}
                  transactions={
                    select_amount == 'KES'
                      ? balance[0]?.transactions?.unsettled_transactions
                      : select_amount == 'USD' && !!balance
                        ? balance[0]?.transactions?.unsettled_transactions
                        : ''
                  }
                />
              </Menu>
            </ThemeProvider>
            {!!user && !!user.role_name && user.role_name == 'Associate' ? (
              <>
                <div className="add_new_text_p">
                  You do not have sufficient permissions for this role
                </div>
              </>
            ) : !select_amount ? (
              <ThemeProvider theme={theme}>
                <ChooseCurrency
                  countryList={countryList}
                  onSavecurrancy={onSavecurrancy}
                // setCurrancylist={setSelect_amount}
                />
              </ThemeProvider>
            ) : (
              <>
                <ThemeProvider theme={theme}>
                  <div className="card_select_data">
                    <div
                      className="main_div_balance_data"
                      style={{
                        display: 'flex',
                        width: '100%',
                        gap: "16px",
                      }}
                    >
                      {/* {user?.role_name == 'Developer'||user?.role_name == 'Director' ? ( */}


                      <SkeletonCom loader={loderinapi}
                        element={
                          <div
                            onClick={() => {
                              setCardTypeSelect("ALL")
                              var serdata: any = {
                                search: "",
                                startdate: "",
                                enddate: "",
                                payee: "",
                                type: "",
                                pagedata: "1",
                                category: "",
                                select_amount: select_amount,
                                settlement: ""
                              };
                              setSearch('');
                              setCateg("");
                              setType_data("");
                              setSettlement("");
                              setSelect_data("");
                              setStartDate('');
                              setEndDate('');
                              setPagelist(1);
                              setRemovedata(10);
                              getBalanceList(serdata);
                            }}
                            style={{ cursor: 'pointer' }}
                            className={cardTypeselect == "ALL" ? 'balance__card_TWO' : "balance__card"}
                          >
                            <div className={cardTypeselect == "ALL" ? 'balance__card_TWO__row' : 'balance__card__row'}>
                              <span className={'balance__card_TWO__unit'}>
                                {select_amount}
                              </span>
                              <span className={'balance__card_TWO__amount'}>
                                {!!balance && balance[0]?.total_balance
                                  ? (balance[0]?.total_balance / 100).toLocaleString(
                                    'en',
                                    { minimumFractionDigits: 2 }
                                  )
                                  : '0.00'}
                              </span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: 'space-between',
                                marginTop: '0px',
                                paddingTop: '4px',
                              }}
                            >
                              <div className="left" style={{ fontSize: '13px' }}>
                                Total Balance
                              </div>
                              {/* <div className="right" style={{ fontSize: '13px' }}>
                          <span>{select_amount}</span>{' '}
                          {!!balance && balance?.available_balance
                            ? (balance?.available_balance / 100).toLocaleString(
                                'en',
                                {
                                  minimumFractionDigits: 2,
                                }
                              )
                            : '0.00'}
                        </div> */}
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: 'space-between',
                                paddingTop: '1px',
                              }}
                            >
                              <div className="left" style={{ fontSize: '13px' }}>
                                Available Balance{' '}
                                <Tooltip title={"Available Balance is the Total Balance less the Unsettled Balance and Pending Balance."}>
                                  <Button
                                    className="button_iconpanding"
                                    style={
                                      select_amount === 'KES'
                                        ? { backgroundColor: '#fff' }
                                        : { backgroundColor: '#F2F8F7' }
                                    }
                                  // onClick={(e) => { handleClick(e, subtitle) }}
                                  >
                                    <InfoIcon />
                                  </Button>
                                </Tooltip>
                              </div>
                              <div className="right" style={{ fontSize: '13px' }}>
                                <span>{select_amount}</span>{' '}
                                {!!balance && balance[0]?.available_balance
                                  ? (balance[0]?.available_balance / 100).toLocaleString(
                                    'en',
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )
                                  : '0.00'}
                              </div>
                            </div>
                          </div>}>

                      </SkeletonCom>
                      <SkeletonCom loader={loderinapi}
                        element={
                          <BalanceCard
                            cardTypeselect={cardTypeselect}
                            onClickCard={(el: any) => {
                              setCardTypeSelect(el)
                              var serdata: any = {
                                search: "",
                                startdate: "",
                                enddate: "",
                                payee: "",
                                type: "",
                                pagedata: 1,
                                category: "",
                                select_amount: select_amount,
                                settlement: "unsettled"
                              };
                              setSearch('');
                              setType_data("");
                              setSettlement("unsettled");
                              setSelect_data("");
                              setStartDate('');
                              setEndDate('');
                              setPagelist(1);
                              setRemovedata(10);
                              setCateg('');
                              getBalanceList(serdata);
                            }}
                            select_amount={select_amount}
                            balance={balance}
                            // handleClick={handleClick_unsettled}
                            title="Unsettled Balance"
                            subtitle="Unsettled Transactions"
                            amount={
                              !!balance && balance[0]?.unsettled_balance
                                ? (balance[0]?.unsettled_balance / 100).toLocaleString(
                                  'en',
                                  { minimumFractionDigits: 2 }
                                )
                                : '0.00'
                            }
                            count={
                              !!balance && balance[0]?.transactions?.no_of_unsettled_transactions
                                ? (balance[0]?.transactions?.no_of_unsettled_transactions)
                                : '0'
                            }
                          />}></SkeletonCom>
                      <SkeletonCom loader={loderinapi}
                        element={
                          <BalanceCard
                            cardTypeselect={cardTypeselect}
                            onClickCard={(el: any) => {
                              setCardTypeSelect(el)
                              var serdata: any = {
                                search: "",
                                startdate: "",
                                enddate: "",
                                payee: "pending",
                                type: "",
                                pagedata: 1,
                                category: "",
                                select_amount: select_amount,
                                settlement: ""
                              };
                              console.log(serdata, "serdataserdata1236");

                              setSearch('');
                              setCateg("");
                              setType_data("");
                              setSettlement("");
                              setStartDate('');
                              setEndDate('');
                              setPagelist(1);
                              setRemovedata(10);
                              setSelect_data("pending");
                              getBalanceList(serdata);
                            }}
                            select_amount={select_amount}
                            balance={balance}
                            // handleClick={handleClick}
                            title="Pending Balance"
                            subtitle="Pending Transactions"
                            amount={
                              !!balance && balance[0]?.on_hold_balance
                                ? (balance[0]?.on_hold_balance / 100).toLocaleString(
                                  'en',
                                  { minimumFractionDigits: 2 }
                                )
                                : '0.00'
                            }
                            count={
                              !!balance && balance[0]?.transactions?.no_of_niobi_unified_transactions
                                ? (balance[0]?.transactions?.no_of_niobi_unified_transactions)
                                : '0'
                            }
                          />}></SkeletonCom>
                    </div>
                  </div>
                  <div className="balance__search-row">
                    <SkeletonDashboardV3 width={'100%'} loader={loderinapi}
                      element={
                        <div className="dflex">

                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '16%' }}
                          >
                            <InputGroup className="balance__filter-wrapper input">
                              <InputGroup.Text id="basic-addon1" className="icon">
                                <SearchIcon />
                              </InputGroup.Text>
                              <Form.Control
                                style={{ fontSize: "11px" }}
                                className="balance-search-input"
                                placeholder="Search by amount"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                disabled={loderinapi}
                                value={search}
                                onChange={(event) => {
                                  console.log('testing');
                                  if (event.target.value === '') {
                                    setSearch(event.target.value);
                                  } else {
                                    setSearch(event.target.value);
                                  }
                                }}
                              />
                            </InputGroup>
                          </div>



                          <>
                            {!!user &&
                              user.role_name &&
                              user.role_name == 'Developer' ? (
                              ''
                            ) : (
                              <div
                                className="conteanaha_data_ketete"
                                style={{ width: '15%' }}
                              >
                                <div className=" balance_cat">
                                  <Form.Select
                                    className='form-select select-primary-color'
                                    style={{ fontSize: "11px" }}
                                    onChange={(e: any) => {
                                      setCateg(e.target.value);
                                      var serdata: any = {
                                        search: search,
                                        startdate: startDate,
                                        enddate: endDate,
                                        payee: select_data,
                                        type: data_type,
                                        pagedata: pageneshn,
                                        category: e.target.value,
                                        select_amount: select_amount,
                                        settlement: settlement
                                      };
                                      getBalanceList(serdata);
                                    }}
                                    value={categ}
                                  >
                                    <option className="OptionSelect" selected value="">
                                      Payment Channel
                                    </option>
                                    {paymentChannels?.map(
                                      (arrayElement: any, index: number) => {
                                        return (
                                          <option
                                            className="OptionSelect"
                                            value={arrayElement.key}
                                            key={index + 1}
                                          >
                                            {arrayElement.key}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Form.Select>
                                </div>
                              </div>
                            )}
                          </>

                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '9%' }}
                          >
                            <div className=" balance_cat">
                              <Form.Select
                                name="supplier_id"
                                style={{ fontSize: "11px" }}
                                onChange={(e: any) => {
                                  setType_data(e.target.value);
                                  var serdata: any = {
                                    search: search,
                                    startdate: startDate,
                                    enddate: endDate,
                                    payee: select_data,
                                    type: e.target.value,
                                    pagedata: pageneshn,
                                    category: categ,
                                    select_amount: select_amount,
                                    settlement: settlement
                                  };
                                  getBalanceList(serdata);
                                }}
                                value={data_type}
                              >
                                <option selected value="">
                                  Type
                                </option>
                                {type_select?.map(
                                  (arrayElement: any, index: number) => {
                                    return (
                                      <option value={arrayElement.id} key={index + 1}>
                                        {arrayElement.name}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                            </div>
                          </div>


                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '10%' }}
                          >
                            <div className=" balance_cat">
                              <Form.Select
                                style={{ fontSize: "11px" }}
                                name="supplier_id"
                                onChange={(e: any) => {
                                  setSettlement(e.target.value);
                                  var serdata: any = {
                                    search: search,
                                    startdate: startDate,
                                    enddate: endDate,
                                    payee: select_data,
                                    type: data_type,
                                    pagedata: pageneshn,
                                    category: categ,
                                    select_amount: select_amount,
                                    settlement: e.target.value
                                  };
                                  getBalanceList(serdata);
                                }}
                                value={settlement}
                              >
                                <option selected value="">
                                  Settlement
                                </option>
                                {setlementType?.map(
                                  (arrayElement: any, index: number) => {
                                    return (
                                      <option value={arrayElement.id} key={index + 1}>
                                        {arrayElement.name}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                            </div>
                          </div>


                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '12%' }}
                          >
                            <div className=" balance_cat">
                              <Form.Select
                                style={{ fontSize: "11px" }}
                                name="supplier_id"
                                onChange={(e: any) => {
                                  setSelect_data(e.target.value);
                                  var serdata: any = {
                                    search: search,
                                    startdate: startDate,
                                    enddate: endDate,
                                    payee: e.target.value,
                                    type: data_type,
                                    pagedata: pageneshn,
                                    category: categ,
                                    select_amount: select_amount,
                                    settlement: settlement
                                  };
                                  getBalanceList(serdata);
                                }}
                                value={select_data}
                              >
                                <option selected value="">
                                  Transaction Status
                                </option>
                                {transactionStatus?.map(
                                  (arrayElement: any, index: number) => {
                                    return (
                                      <option value={arrayElement.id} key={index + 1}>
                                        {arrayElement.name}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                            </div>
                          </div>

                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '18%' }}
                          >
                            <DatePickerll

                              placeholderText="Select Date"
                              dateFormat="dd MMMM yyyy"
                              className="dashboard-container__datepicker"
                              selectsRange
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={today}
                              onChange={(dates: any) => {
                                const [start, end] = dates;
                                setStartDate(start);
                                setEndDate(end);
                                console.log(dates, 'clicked range');
                              }}
                            />
                          </div>

                          <div
                            className="conteanaha_data_ketete"
                            style={{ width: '12%' }}
                          >
                            <div className=" balance_cat">
                              {!!datatabal && datatabal.length > 0 && (
                                <>
                                  <Button
                                    onClick={() => {
                                      //setDounload_popup_date(true);
                                      setDounload_popup(true);
                                    }}
                                    className="Download_Button"
                                    style={{ textAlign: 'left' }}
                                  >
                                    <MobileIcon />
                                    <p style={{ fontSize: "11px" }}> Download</p>
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      }></SkeletonDashboardV3>
                  </div>

                  <div className="balance__table">
                    <>
                      <SkeletonCom loader={loderinapi}
                        element={
                          <>
                            <BalanceTable
                              settlement={settlement}
                              loderdata={loderinapi}
                              data={datatabal}
                              onSelect={(el: any) => {
                                console.log('ele', el);

                                setshowTransMoodal(true);
                                settransData(el);
                              }}
                            />
                            <div className="addnewbtnclass">
                              <div className="inpagenum">
                                <p>
                                  {remove - 9}-{remove}
                                </p>
                              </div>
                              <div className="btnmaindivin">
                                {pageneshn == 1 ? (
                                  <button disabled className="nextntmistish">
                                    <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                  </button>
                                ) : (
                                  <button
                                    className="nextntmistish"
                                    onClick={() => {
                                      setPagelist(pageneshn - 1);
                                      setRemovedata(remove - 10);
                                    }}
                                  >
                                    <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                  </button>
                                )}
                                {remove > totalCount ? (
                                  <button className="nextntmistish">
                                    <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                  </button>
                                ) : (
                                  <button
                                    // disabled={totalCount>=pageneshn?false:true}
                                    className="nextntmistish"
                                    onClick={() => {
                                      setPagelist(pageneshn + 1);
                                      setRemovedata(remove + 10);
                                    }}
                                  >
                                    <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        }></SkeletonCom>
                    </>
                  </div>
                </ThemeProvider>
              </>
            )}
          </div>
        </>

        // :}
        // </>
      )}
      {Dounload_popup && (
        <SelectPopup
          data={csvUrl}
          show={Dounload_popup}
          onClose={() => {
            setDounload_popup(false);
          }}
          OnSave={(data: any) => {
            setSelect_popup(data);
            setDounload_popup(false);
            if (data == 'Export as CSV') {
              window.location.href = csvUrl?.data
              // setDounload_popup_pdf(true);
            } else if (data == 'Export as PDF') {
              if (!csvUrl?.pdf) {
                setShowModal(true);
              } else {
                window.open(csvUrl?.pdf, '_blank');
              }
            }
          }}
        />
      )}
      {Dounload_popup_pdf && (
        <Popup
          data={csvUrl}
          show={Dounload_popup_pdf}
          onClose={() => {
            setDounload_popup_pdf(false);
          }}
          search={search}
          startdate={startDate}
          enddate={endDate}
          OnSave={(data: any) => {
            // setSelect_popup(data)
            // setSelect_pdf(data)
            setDounload_popup_pdf(false);
          }}
        />
      )}
      {topup_balk_data && (
        <Topup_balk_data
          show={topup_balk_data}
          onClose={() => {
            setShowBalanceGrid(true);
            setTopup_balk_data(false);
          }}
        />
      )}
      {currencySelect &&
        <Select_source_account onSave={(el: any) => {
          console.log(el, "sdfszffzsffffxcxfgcf");

          if (el == "KES") {
            setShowTopup_two(true);
            setCurrencySelect(false)
            setSelectCurrency("KES")
          } else if (el == "USD") {
            setSelectMethod(true)
            setCurrencySelect(false)
            setSelectCurrency("USD")
          } else {
            setShowTopup(true)
            setCurrencySelect(false)
            setSelectCurrency(el)
          }

        }}
          typeButton={typeButton}
          currancyselect={select_amount}
          pageName="Balance"
          onClose={() => {
            setCurrencySelect(false)
          }}
          show={currencySelect}

        />

      }
      {selectMethod &&

        <SelectMethod
          onSave={(el: any) => {
            setSelectMethod(false)
            if (el == "Express Payment USD") {
              navigate(`/payment-journey?type=${el}`);
            }
          }}
          onSaveCurrancy={(el: any) => {
            if (el == "KES") {
              setShowTopup_two(true);
              setCurrencySelect(false)
              setSelectMethod(false)
              setSelectCurrency("KES")
            } else {
              setSelectMethod(true)
              setCurrencySelect(false)
              setSelectMethod(false)
              setSelectCurrency("USD")
            }

          }}
          onClose={() => {
            setSelectMethod(false)
          }}
          values={SelectCurrency}
          show={selectMethod}
        />

      }
      {showTopup_two && (
        <TopupModal_two
          show={showTopup_two}
          onSaveCurrancy={(el: any) => {
            if (el == "KES") {
              setShowTopup_two(true);
              setCurrencySelect(false)
              setShowTopup_two(false);
              setSelectCurrency("KES")
            } else {
              setSelectMethod(true)
              setCurrencySelect(false)
              setShowTopup_two(false);
              setSelectCurrency("USD")
            }

          }}
          values={SelectCurrency}
          onClose={() => {
            setShowTopup_two(false);
          }}
          onSave={(el: boolean) => {
            setShowTopup_two(false);
            setTopupMethod(el);
            setTopup(true);
            setShowBalanceGrid(false);
          }}
          onBalkpaymeant={(el: boolean) => {
            setShowTopup_two(false);
            setTopupMethod(el);
            setTopup_balk_data(true);
            setShowBalanceGrid(false);
          }}
          onconfiam={(el: boolean) => {
            setPaymentGrid(true);
            setShowBalanceGrid(false);
            setTopup(false);
          }}
        />
      )}
      {showTopup && (
        <TopupModal
          show={showTopup}
          onClose={() => {
            const event = 'Top up canceled from Balance';
            const eventProperties = {
              userId:
                !!properties && !!properties.user_id
                  ? properties.user_id
                  : null,
              userName:
                !!properties && !!properties.name ? properties.name : null,
              email:
                !!properties && !!properties.email ? properties.email : null,
              pageUrl: window.location.href,
            };
            amplitude.getInstance().logEvent(event, eventProperties);
            posthog.capture(event, eventProperties);
            setShowTopup(false);
            setShowBalanceGrid(true);
          }}
          onSave={(el: boolean) => {
            setShowTopup(false);
            setTopupMethod(el);
            setTopup(true);
            setShowBalanceGrid(false);
          }}
          select_Currency={select_amount}
          pageName="Balance"
        />
      )}

      {showTransMoodal && (
        <TransactionDetails
          PageType={''}
          show={showTransMoodal}
          onClose={() => {
            setshowTransMoodal(false);
            settransData('');
          }}
          data={transData}
        />
      )}
      {verefire_data && (
        <Virefily_account
          show={verefire_data}
          onClose={() => {
            setVirefay_data(false);
          }}
        />
      )}
      {topUp && (
        <TopupDetail
          topupMethod={topUpMethod}
          onReturn={() => {
            setTopup(false);
            setShowBalanceGrid(true);
          }}
        />
      )}
      {paymentGrid && (
        <PayableGrid
          onCancel={() => {
            setShowTopup_two(false);
            setPaymentGrid(false);
            setShowBalanceGrid(true);
          }}
        />
      )}
      <></>
    </>
  );
};
export const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 11 11"
    fill="none"
  >
    <path d="M5.3333 7.50039V4.90039" stroke="#025041" stroke-linecap="round" />
    <path
      d="M5.33333 3.16654C5.57265 3.16654 5.76666 3.36055 5.76666 3.59987C5.76666 3.83919 5.57265 4.0332 5.33333 4.0332C5.094 4.0332 4.89999 3.83919 4.89999 3.59987C4.89999 3.36055 5.094 3.16654 5.33333 3.16654Z"
      fill="#025041"
    />
    <path
      d="M3.16666 1.5798C3.82512 1.19886 4.57261 0.998833 5.33333 1.00001C7.72662 1.00001 9.66665 2.94004 9.66665 5.33333C9.66665 7.72663 7.72662 9.66666 5.33333 9.66666C2.94003 9.66666 1 7.72663 1 5.33333C1 4.54423 1.21103 3.80367 1.5798 3.16667"
      stroke="#025041"
      stroke-linecap="round"
    />
  </svg>
);
const MobileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 11.9412V13.4706C3 13.8762 3.15804 14.2652 3.43934 14.552C3.72064 14.8389 4.10218 15 4.5 15H13.5C13.8978 15 14.2794 14.8389 14.5607 14.552C14.842 14.2652 15 13.8762 15 13.4706V11.9412M5.25 7.35294L9 11.1765M9 11.1765L12.75 7.35294M9 11.1765V2"
      stroke="#025041"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
