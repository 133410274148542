import { Box, Grid } from "@mui/material";
import styles from "../Pay.module.scss";
import { Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../_reducers/paymentJourney.reducer";
import { useEffect, useState } from "react";

interface Props {
  nextpage: any;
  data: any;
  entityid: any;
  shortcode: any;
  payload: any;
  setpayload: any;
  contryCode: any;
  GoBack: any;
  loading: any;
  environment: any;
}
const Setp2 = ({
  payload,
  setpayload,
  data,
  entityid,
  shortcode,
  nextpage,
  GoBack,
  contryCode,
  loading,
  environment
}: Props) => {
  const [tabdata, setTabdata] = useState();
  const [correspondent_data, setCorrespondent_data] = useState("");
  const [pohon_data, setPhone] = useState<any>();
  const [data_stastes, setStast_data] = useState(false);
  const [startgetapi, setStartgetapi] = useState(true);
  const [render, setRender] = useState(true);
  const [countryList_api, setCountrtList_api] = useState<any>([]);
  const [country_sortname, setCountry_sortname] = useState<any>("");
  const dispatch = useDispatch();
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  // useEffect(() => {
  //   if (render) {
  //     setRender(false);
  //     dispatch(
  //       paymentJourneyActions.getCountriesDetails(payload.country_id) as any
  //     );
  //   }
  // }, [render]);
  console.log(countryList_api, dataList, correspondent_data, "dataListdataList");

  useEffect(() => {
    if (startgetapi && dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS !== undefined) {
      setCountrtList_api(dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS?.data);
      // console.log(dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS?.data,dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS,"dsfzsdfffffff");

      // setTabdata(dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS?.data[0]?.id);
      // setCorrespondent_data(
      //   dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS?.data[0]?.correspondent
      // );
      setStartgetapi(false);
    }
  }, [startgetapi, dataList?.CHACKBALANCE_PAYMENT_LINK_SUCCESS]);
  const handsubmit = () => {
    if (environment == "pro") {
      if (pohon_data.length == 12 && !!tabdata) {
        let payloadData = payload;
        payloadData.mobile = pohon_data;
        payloadData.country_payment_method_id = tabdata;
        payloadData.correspondent = correspondent_data;
        setpayload(payloadData);
        nextpage();
      }
    }
    else {
      if (pohon_data.length > 4 && !!tabdata) {
        let payloadData = payload;
        payloadData.mobile = pohon_data;
        payloadData.country_payment_method_id = tabdata;
        payloadData.correspondent = correspondent_data;
        setpayload(payloadData);
        nextpage();
      }
    }
  };
  return (
    <>
      <Box
        className={styles.from_contean_data_setp2}
        sx={{ position: "relative" }}
      >
        <Box className={styles.tab_contean_button}>
          <Grid container>
            {countryList_api?.map(
              (arrayElement: any, index: number) => {
                return (
                  <Grid item md={6} sm={6} xs={6}>
                    <Button
                      disabled={arrayElement.status == "inactive" ? true : false}
                      className={
                        tabdata == arrayElement.id
                          ? styles.mpesa_button
                          : styles.difolt_button
                      }
                      onClick={() => {
                        setTabdata(arrayElement.id);
                        setCorrespondent_data(arrayElement.correspondent);
                      }}
                    >
                      <div className={styles.mpsaimg}>
                        <img
                          width={"100%"}
                          height={"100%"}
                          src={arrayElement.logo}
                        />
                      </div>
                      {arrayElement.title}
                    </Button>
                  </Grid>
                );
              }
            )}
          </Grid>

        </Box>
        {data_stastes == true && !tabdata ? (
          <p className="err_calss_display">
            Please select a payment method</p>) : ('')}
        <div style={{ paddingTop: "20px" }}>
          <p style={{ fontWeight: "400" }} className="discrip_the_ahhahas">
            Hello,
          </p>
          <p style={{ fontWeight: "400" }} className="discrip_the_ahhahas">
            You will shortly receive a prompt on your phone to enter your mobile money PIN to complete the payment. Please ensure your phone is on and unlocked to complete the process.
          </p>

          <div className="company-details-container__wrapper">
            <div className="input-form-wrapper">
              <label>Mobile</label>
              <PhoneInput
                country={contryCode}
                // prefix='+'
                value={pohon_data}
                onlyCountries={[contryCode]}
                localization={{ de: contryCode }}
                onChange={(value, country, e, formattedValue) => {
                  console.log(value, e, "aahauyaahaha");
                  setPhone(value);
                }}
                inputClass={"inputBx-mobile readonly"}
              />

              {environment == "pro" ?
                <>
                  {data_stastes == true && pohon_data == undefined ? (
                    <p className="err_calss_display">Mobile is required</p>
                  ) : 
                    ""
                  }</>
                :
                <>
                  {data_stastes == true && pohon_data == undefined ? (
                    <p className="err_calss_display">Mobile is required</p>
                  ) : ("")
                  }
                </>
              }
              <div className={styles.button_bottomcontean}>
                <div className={styles.buttonContean}>
                  <Button
                    id={styles.contenButtonPay}
                    className="balance__white-btn"
                    onClick={() => {
                      GoBack(1);
                    }}
                  >
                    Go Back
                  </Button>
                  {!!pohon_data && !!tabdata ? (
                    <Button
                      id={styles.contenButtonPay}
                      className="balance__save-btn"
                      onClick={() => {
                        handsubmit();
                        setStast_data(true);
                      }}
                    >
                      {loading == true && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Pay
                    </Button>
                  ) : (
                    <Button
                      id={styles.contenButtonPay}
                      className="balance__save-btn"
                      onClick={() => {
                        setStast_data(true);
                      }}
                    >
                      {loading == true && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Pay
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box >
    </>
  );
};
export default Setp2;
