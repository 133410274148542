import RegisterLayout from "../Layout/RegisterLayout";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userActions } from '../../../_actions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../Auth.css';
import { UserRequest } from '../../../interfaces/interfaces';
import showPwdImg from '../ShowPassword/show-password.svg';
import hidePwdImg from '../ShowPassword/hide-password.svg';
import { LoginHeader } from '../LoginHeader';
import { getUser, isUserAuthenticated } from '../../../_helpers';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { registerSelector } from '../../../_reducers/registration.reducer';
import { Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import SimpleImageSlider from 'react-simple-image-slider';
import CarouselLogin from './carousel';
import amplitude from 'amplitude-js';
import { useSearchParams } from 'react-router-dom';
import posthog from 'posthog-js';
import EntityLayout from "../Layout/entityLayout";
import { Button, Modal } from "react-bootstrap";
const NewRegister = ({ api, path }: any) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [datadisplay, setDatadisplay] = useState('');

    interface countryProps {
        dialCode: string;
    }

    useEffect(() => {
        const popup = searchParams.get('partner');
        if (!!popup) {
            setDatadisplay(popup);
        }
    }, [searchParams]);
    const [user, setUser] = useState<UserRequest>({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
        country_code: '+254',
        mobile: '',
        terms: false,
    });
    const authUser = getUser()?.data ?? null;

    useEffect(() => {
        if (authUser && isUserAuthenticated()) {
            navigate(authUser.hasEntity ? '/dashboard' : '/v2/account-setup');
        }
    }, []);

    const [submitted, setSubmitted] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [phone, setPhone] = useState('');
    const [country_code, setcountry_code] = useState('');
    const [phoneinit, setphoneinit] = useState(true);
    const [registerRender, setregisterRender] = useState(true);
    const [regfailerRender, setregfailerRender] = useState(true);
    const [registered, setregistered] = useState<any>();
    const [registerReqeust, setregisterReqeust] = useState(true);
    const [loading, setLoading] = useState(false);
    const [bodyDATA, setBodyData] = useState<any>({})
    const [showModal, setShowModal] = useState(false)
    const [message, setMessage] = useState("")
    //const registering = useSelector(state => state.registration.registering)
    const dispatch = useDispatch();

    const registerData = useSelector((state) => registerSelector(state));
    console.log(registered, registerData.REGISTER_SUCCESS, bodyDATA, "registeredregistered123");

    function resetResquest() {
        setregisterRender(true);
        setregfailerRender(true);
    }
    console.log(registerData?.REGISTER_FAILURE, regfailerRender, "registerData?.REGISTER_FAILURE");

    useEffect(() => {
        if (regfailerRender && registerData?.REGISTER_FAILURE !== undefined) {
            console.log(registerData?.REGISTER_FAILURE, regfailerRender, "registerData?.REGISTER_FAILURE");

            setregfailerRender(false);
            setLoading(false);
            setregisterReqeust(true);
        }
    }, [regfailerRender, registerData?.REGISTER_FAILURE]);

    useEffect(() => {
        if (registerRender && registerData?.REGISTER_SUCCESS !== undefined) {
            setregisterRender(false);
            setregistered(registerData.REGISTER_SUCCESS);
            handleSubmitDevAPI()
            // setLoading(false);
            setregisterReqeust(true);
        }
    }, [registerData?.REGISTER_SUCCESS, registerRender]);

    // useEffect(() => {
    //     if (registerReqeust && registerData?.REGISTER_REQUEST !== undefined) {
    //         console.log('loading', registerReqeust);
    //         resetResquest();
    //         setregisterReqeust(false);
    //         // setLoading(registerData.REGISTER_REQUEST);
    //     }
    // }, [registerReqeust]);


    console.log(registerData, loading, 'RegisterData');

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('Please enter your first name.'),
        last_name: Yup.string().required('Please enter your last name.'),
        email: Yup.string()
            .required('Please enter a valid email address.')
            .email('Please enter a valid email address.'),
        password: Yup.string()
            .required('Please enter your password.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                'Password must have at least 8 characters, including atleast one uppercase letter, one lowercase letter, one number, and a special character.'
            ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords do not match.')
            .required('Please enter your password.'),
        terms: Yup.bool().oneOf(
            [true],
            'Please accept the Terms of Use and Privacy Policy.'
        ),
    });
    const handleSubmitDevAPI = () => {
        if (phone.length >= 9) {
            var req = bodyDATA
            var URLApi = process.env.REACT_APP_STAGING_API_URL
            // setSubmitted(true);
            setLoading(true);
            // setMessage("Setting up your Sandbox credentials")
            if (bodyDATA?.email && bodyDATA?.password) {
                dispatch(userActions.register(URLApi, { req }, "/entity") as any);
            }
        }
    };
    const handleSubmit = (e: any) => {
        console.log(e, 'efgxdxgggxdg', phone);
        if (phone.length >= 9) {
            var req = {
                first_name: e.first_name,
                last_name: e.last_name,
                email: e.email,
                password: e.password,
                confirm_password: e.confirm_password,
                country_code: country_code ? country_code : user.country_code,
                mobile: e.mobile ? e.mobile : user.mobile,
                partner: datadisplay,
            };
            var URLApi = process.env.REACT_APP_API_URL
            console.log(req, 'e', phone);
            const event = 'User registration';
            const eventProperties = {
                userId: null,
                userName:
                    !!req && !!req.first_name && !!req.last_name
                        ? `${req.first_name} ${req.last_name}`
                        : null,
                email: !!req && !!req.email ? req.email : null,
                pageUrl: window.location.href,
            };

            amplitude.getInstance().logEvent(event, eventProperties);
            posthog.capture(event, eventProperties);
            setBodyData(req)
            setSubmitted(true);
            resetResquest();
            setLoading(true);
            // setMessage("Setting up your production credentials")
            if (e.email && e.password) {
                dispatch(userActions.register({ req }, path, api) as any);
            }
        }
    };
    const handleOnChange = (
        value: any,
        country: any,
        e: any,
        formattedValue: any
    ) => {
        setPhone(formattedValue.substring(country?.dialCode?.length + 1));
        setUser((prev) => ({
            ...prev,
            country_code: country?.dialCode,
            mobile: formattedValue.substring(country?.dialCode?.length + 1),
        }));
    };
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    return (
        <>
            <EntityLayout>
                <ThemeProvider theme={theme}>
                    {/* {
                        (loading == true) && (
                            <Modal
                                show={loading}
                                backdrop="static"
                                keyboard={false}
                                aria-labelledby='ModalHeader'
                                className="topup_modal"
                                centered
                                size="lg"
                            >
                                <Modal.Body>
                                    <>

                                        <div className="div_pading_data">
                                            <img src="/logo 3.svg" />
                                            <p className="des_data">
                                                {message}
                                            </p>
                                            <img
                                                width={75}
                                                height={75}
                                                src="/Niobi Loading Animation-Cross.gif"
                                            />
                                        </div>
                                    </>
                                </Modal.Body>
                            </Modal>
                        )
                    } */}
                    <div className="formBx_tow" style={{ overflow: "scroll" }}>

                        <Formik
                            initialValues={user}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors, touched, isSubmitting, handleChange, values }) => (
                                <Form className="form-input_line">
                                    {/* <div id="req_text_atage" className="inputBx" style={{ padding: "50px 0px 35px 0px" }}>
                                    <p style={{fontSize:"12px"}}>
                                            <Link style={{ textDecoration: "none", fontWeight: "600", fontSize: "12PX" }} to="https://niobi.co/request-a-demo/">
                                                Request a demo
                                            </Link>
                                        </p>
                                    </div> */}
                                      <div className="inputBx" style={{ padding: "50px 0px 35px 0px" }}>
                                        <p className="w-100 " style={{ textAlign: "end" }}>
                                            See how it works? {' '}
                                            <Link to="https://niobi.co/request-a-demo">Request a demo</Link>
                                        </p>
                                    </div>
                                    <div className="classname_login_page" >
                                        <p className="p_Signup_heding">
                                            Sign up
                                        </p>
                                        <div className="first-last-name">
                                            <div className="inputBx">
                                                <span>First Name</span>
                                                <Field
                                                    name="first_name"
                                                    type="text"
                                                    placeholder="First Name"
                                                    className={
                                                        'form-text form-control' +
                                                        (errors.first_name && touched.first_name
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                />
                                                <ErrorMessage
                                                    name="first_name"
                                                    component="div"
                                                    className="invalid-feedback red"
                                                />
                                            </div>
                                            <div className="inputBx">
                                                <span>Last Name</span>
                                                <Field
                                                    name="last_name"
                                                    type="text"
                                                    placeholder="Last Name"
                                                    className={
                                                        'form-text form-control' +
                                                        (errors.last_name && touched.last_name
                                                            ? ' is-invalid'
                                                            : '')
                                                    }
                                                />
                                                <ErrorMessage
                                                    name="last_name"
                                                    component="div"
                                                    className="invalid-feedback red"
                                                />
                                            </div>
                                        </div>
                                        <div className="inputBx">
                                            <span>Email</span>
                                            <Field
                                                name="email"
                                                type="text"
                                                placeholder="Email"
                                                className={
                                                    'form-text form-control' +
                                                    (errors.email && touched.email ? ' is-invalid' : '')
                                                }
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="invalid-feedback red"
                                            />
                                        </div>
                                        {/* <div className="inputBx">
                            <span>Mobile</span>
                            <PhoneInput
                                country={'ke'}
                                prefix='+'
                                value={values.mobile}
                                onChange={(value,country,e,formattedValue)=>{
                                    handleOnChange(value,country,e,formattedValue);
                                    handleChange('mobile');
                                } }
                                inputClass={"inputBx-mobile"}
                                autoFormat={false}
                                />
                            </div> */}
                                        <div className="inputBx">
                                            <span>Mobile</span>
                                            <PhoneInput
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                country={'ke'}
                                                prefix="+"
                                                value={values.mobile}
                                                onChange={(
                                                    value,
                                                    country: countryProps,
                                                    e,
                                                    formattedValue
                                                ) => {
                                                    handleOnChange(value, country, e, formattedValue);
                                                    handleChange('mobile');
                                                    console.log(
                                                        formattedValue,
                                                        value,
                                                        country,
                                                        phone,
                                                        'formattedValue'
                                                    );
                                                    setphoneinit(false);
                                                    setcountry_code(country.dialCode);
                                                    //setPhone(formattedValue);
                                                }}
                                                inputClass={'inputBx-mobile'}
                                                autoFormat={false}
                                            //onChange={(e) => setPhone(e)}
                                            />
                                            {phoneinit == false ? (
                                                !!phone ? (
                                                    phone.length < 9 ? (
                                                        <div className="red invalid-feedback d-block">
                                                            Phone number has min 9 characters
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )
                                                ) : (
                                                    <div className="red invalid-feedback d-block">
                                                        Phone number is required
                                                    </div>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {/* <div className="inputBx">
                <div className="mobile-no-input">
                  <div className="inputBx-mobile-prefix">
                    <span>Mobile</span>
                    <Field
                      name="country_code"
                      as="select"
                      className={
                        "form-text form-control select-box" +
                        (errors.country_code && touched.country_code
                          ? " is-invalid"
                          : "")
                      }
                    >
                      <option value="+254">+254</option>
                      <option value="+255">+255</option>
                      <option value="+256">+256</option>
                    </Field>
                    <ErrorMessage
                      name="country_code"
                      component="div"
                      className="invalid-feedback red"
                    />
                  </div>
                  <div className="inputBx-mobile">
                    <span></span>
                    <Field
                      name="mobile"
                      type="text"
                      placeholder=""
                      className={
                        "form-text form-control" +
                        (errors.mobile && touched.mobile ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="invalid-feedback red"
                    />
                  </div>
                </div>
              </div>*/}
                                        <div className="inputBx">
                                            <span>Password</span>
                                            <div
                                                className={
                                                    'pwd-container' +
                                                    (errors.password && touched.password
                                                        ? ' is-invalid'
                                                        : '')
                                                }
                                            >
                                                <Field
                                                    name="password"
                                                    placeholder="Password"
                                                    type={isRevealPwd ? 'text' : 'password'}
                                                    className={'form-text form-control'}
                                                    value={values.password}
                                                    onChange={handleChange('password')}
                                                />
                                                <img
                                                    title={
                                                        isRevealPwd ? 'Hide password' : 'Show password'
                                                    }
                                                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                    onClick={() =>
                                                        setIsRevealPwd((prevState) => !prevState)
                                                    }
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="inputBx">
                                            <span>Confirm Password</span>
                                            <div
                                                className={
                                                    'pwd-container' +
                                                    (errors.confirm_password && touched.confirm_password
                                                        ? ' is-invalid'
                                                        : '')
                                                }
                                            >
                                                <Field
                                                    name="confirm_password"
                                                    placeholder="Confirm Password"
                                                    type={isRevealPwd ? 'text' : 'password'}
                                                    className={'form-text form-control'}
                                                    value={values.confirm_password}
                                                    onChange={handleChange('confirm_password')}
                                                />
                                                <img
                                                    title={
                                                        isRevealPwd ? 'Hide password' : 'Show password'
                                                    }
                                                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                    onClick={() =>
                                                        setIsRevealPwd((prevState) => !prevState)
                                                    }
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="confirm_password"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        {/* 
                                    <div className="terms">
                                        <Field
                                            name="terms"
                                            type="checkbox"
                                            // checked="false"
                                            // value="false"
                                            className={
                                                errors.terms && touched.terms ? ' is-invalid' : ''
                                            }
                                        />
                                        <span className="pl-1 m-2 font-14">
                                            I have read, understood and I agree to Niobi's{' '}
                                            <a
                                                target="_blank"
                                                className=""
                                                href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf"
                                            >
                                                Terms of Use
                                            </a>{' '}
                                            and{' '}
                                            <a
                                                target="_blank"
                                                className=""
                                                href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf"
                                            >
                                                Privacy Policy
                                            </a>
                                            .
                                        </span>
                                        <ErrorMessage
                                            name="terms"
                                            component="div"
                                            className="invalid-feedback red"
                                        />
                                    </div> */}

                                        {/* <div className='invalid-Login'>
                                <span>The email or password is incorrect. Please retry</span>
                            </div> */}
                                        <div className="terms">
                                            <Field
                                                name="terms"
                                                type="checkbox"
                                                // checked="false"
                                                // value="false"
                                                className={
                                                    errors.terms && touched.terms ? ' is-invalid' : ''
                                                }
                                            />
                                            <span className="pl-1 m-2 font-14">
                                                By signing up you agree to our  {' '}
                                                <a
                                                    target="_blank"
                                                    className=""
                                                    href="https://users.niobi.co/others/Niobi_Terms-of-Use_v1.1.pdf"
                                                >
                                                    Terms of Use
                                                </a>{' '}
                                                and{' '}
                                                <a
                                                    target="_blank"
                                                    className=""
                                                    href="https://users.niobi.co/others/Niobi_Privacy-Policy_v1.1.pdf"
                                                >
                                                    Privacy Policy
                                                </a>
                                                .
                                            </span>
                                            <ErrorMessage
                                                name="terms"
                                                component="div"
                                                className="invalid-feedback red"
                                            />
                                        </div>
                                        <div className="inputBx">
                                            <button
                                                type="submit"
                                                //disabled={submitted}
                                                className="form-button"
                                                onClick={() => setphoneinit(false)}
                                            >
                                                {loading == true && (
                                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                                )}
                                                <span>Create Account</span>
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="footer_text" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                        <a
                                            style={{ textDecoration: "none", fontSize: "14px", paddingRight: "4px" }}
                                            target="_blank"
                                            className=""
                                            href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf"
                                        >
                                            Terms of Use
                                        </a>{' '}/{' '}<a
                                            style={{ textDecoration: "none", fontSize: "14px", paddingLeft: "4px" }}
                                            target="_blank"
                                            className=""
                                            href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf"
                                        >
                                            Privacy Policy
                                        </a>
                                    </div> */}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </ThemeProvider>
            </EntityLayout>

        </>
    )
}
export default NewRegister;