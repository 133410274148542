import "../components/topup-modal/Topup.scss";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { getUser } from "../../../../../_helpers";
import { Button } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { selectPaymentJourneyData } from "../../../../../_reducers/paymentJourney.reducer";
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../_actions/paymentJourney.actions";
import { Doller } from "./topup-modal/doller";
import { selectAllBalanceLIstData } from "../../../../../_reducers/balance.reducer";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import styles from './Choose_currency/ChooseCurrency.module.scss';
import { getProperties, isUserAuthenticated } from '../../../../../_helpers';
import { balanceActions } from '../../../../../_actions/balance.actions';
import amplitude from 'amplitude-js';
import posthog from 'posthog-js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInput from "react-phone-input-2";
import { TransactionDetails } from "./topup-modal/TransactionDetails/TransactionDetails";
import { BankVia } from "./topup-modal/bankVia";
import Dashboard_nodata from "../../Dashboard/components/dashboard_nodata/dashboard_nodata";
import { getEnvironment } from "../../../../../_helpers/environmentProperties";

const Select_source_account = (props: any) => {
  const [showModal, setShow] = useState(props.show == true ? 1 : 0);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [countryList, setCountrtList] = useState([]);
  const [startgetapi, setStartgetapi] = useState(true);
  const [render, setRender] = useState(true);
  const [selectCurrencyPopup, setSelectCurrencyPopup] = useState<any>("");
  const [currancyDestination, setCurrancyDestination] = useState("");
  const [bankResponse, setBankResponse] = useState<any>();
  const [savePaymentDetailsRender, setSavePaymentDetailsRender] =
    useState(false);
  const [success_data, setSuccess_data] = useState('');
  const [balanceRender, setBalanceRender] = useState(true);
  const [balance, setBalance] = useState<any>();
  const [topupDetailsRender, setTopupDetailsRender] = useState(true);
  const [topupDetails, setTopupDetails] = useState<any>();
  const [pohon_data, setPhone] = useState<any>();
  const [phone_validation, setPhoneValidation] = useState(false);
  const [email_data, setEmail_data] = useState('');
  const [loading, setLoading] = useState(false);
  const [payReqrender, setpayReqrender] = useState(false);
  const [payReqSuccrender, setpayReqSuccrender] = useState(false);
  const [payReqResrender, setpayReqResrender] = useState(false);
  const [trans, settrans] = useState<any>();
  const [getTrans, setGettrans] = useState(true);
  const [checkTransapi, setcheckTransapi] = useState(false)
  const [requestRender, setrequestRender] = useState(true);
  const [paymentStep, setpaymentStep] = useState(false);
  const [option, setOption] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [success_data_tan, setSuccess_data_tan] = useState(false);
  const [data_stastes, setStast_data] = useState(false);
  const [bankResponseShow, setBankResponseshow] = useState<any>(false);
  const [balanceamount, setBalanceamount] = useState(0)
  const user = getUser()?.data ?? null;
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const dispatch = useDispatch();
  const data = useSelector((state) => selectAllBalanceLIstData(state));
  const navigate = useNavigate();

  console.log(selectCurrencyPopup, "selectCurrencyPopup");

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(paymentJourneyActions.getCountriesBalances() as any);
    }
  }, [render]);
  useEffect(() => {
    if (startgetapi && dataList?.GETCOUNTRIES_BALANCES_SUCCESS !== undefined) {
      setCountrtList(dataList?.GETCOUNTRIES_BALANCES_SUCCESS);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  const properties = getProperties();

  useEffect(() => {
    if (
      savePaymentDetailsRender &&
      data?.SAVE_PAYMENT_DETAILS_SUCCESS !== undefined
    ) {
      console.log("savePaymentDetailsRender");
      setSavePaymentDetailsRender(false);
      setBankResponse(data.SAVE_PAYMENT_DETAILS_SUCCESS.data);
      setShow(6);
      //   setshowBankSuccess(true);
    }
  }, [data, savePaymentDetailsRender]);

  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(balanceActions.getTopUpDetails() as any);
      dispatch(balanceActions.getPdf_Topup() as any);
    }
  }, [render, isUserAuthenticated]);

  useEffect(() => {
    if (balanceRender && data?.GET_TOPUP_PDF_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(data.GET_TOPUP_PDF_SUCCESS);
    }
  }, [data, balanceRender]);
  useEffect(() => {
    if (topupDetailsRender && data?.GET_TOPUP_DETAILS_SUCCESS !== undefined) {
      setTopupDetailsRender(false);
      setTopupDetails(data.GET_TOPUP_DETAILS_SUCCESS);
    }
  }, [data, topupDetailsRender]);
  const handsubmit = () => {

    var body = {
      mobile: pohon_data,
      amount: email_data,
      unified: true,
      currency: "KES",
      country_id: "1"
    };
    const event = 'Top Up Request made via M-Pesa STK Push';
    const eventProperties = {
      userId: !!properties && !!properties.user_id ? properties.user_id : null,
      userName: !!properties && !!properties.name ? properties.name : null,
      email: !!properties && !!properties.email ? properties.email : null,
      pageUrl: window.location.href,
    };
    amplitude.getInstance().logEvent(event, eventProperties);
    posthog.capture(event, eventProperties);
    dispatch(balanceActions.Stk_deposit_actice(body) as any);
    setLoading(true);
    setpayReqrender(true);
    setTimeout(() => {
      setrequestRender(true);
    }, 3000);
  };
  const onSaveBank = () => {
    setBankResponseshow(true);
    setSavePaymentDetailsRender(true);
    setShow(0);
    // onClose()
  };
  useEffect(() => {
    if (payReqrender && data?.STK_DEPOSIT_REQUEST == false) {
      console.log('useEffect1');
      setpayReqrender(false);
    }
  }, [data, payReqrender]);

  useEffect(() => {
    if (payReqrender && data?.STK_DEPOSIT_REQUEST == true) {
      console.log('useEffect2');
      setSuccess_data('PANDING');
      setpayReqrender(false);
      setpayReqSuccrender(true);
      setpayReqResrender(true);
    }
  }, [data, payReqrender]);
  useEffect(() => {
    if (payReqSuccrender && data?.STK_DEPOSIT_SUCCESS !== undefined) {
      console.log('useEffect3');
      setpayReqSuccrender(false);
      setpaymentStep(true);
      settrans(data.STK_DEPOSIT_SUCCESS);
      setcheckTransapi(true);
    }
  }, [data, payReqSuccrender]);

  console.log(data.STK_DEPOSIT_SUCCESS, 'data?.TRANTOPUP_SUCCESS');

  useEffect(() => {
    if (trans && getTrans && !!trans.data && !!trans.data.MerchantRequestID) {
      setGettrans(false);

      dispatch(
        balanceActions.TransStatustopup(
          data?.STK_DEPOSIT_SUCCESS?.data?.MerchantRequestID
        ) as any
      );
      let timer = setInterval(() => { }, 1000);
      if (!!timer) {
        clearInterval(timer);
      }
    }
  }, [trans, getTrans]);
  console.log(data, 'datadatadatadata');

  useEffect(() => {
    if (payReqResrender && data?.STK_DEPOSIT_FAILURE !== undefined) {
      console.log('useEffect4');
      setpayReqResrender(false);
      setSuccess_data('FAILURE');
    }
  }, [data, payReqResrender]);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  useEffect(() => {
    if (checkTransapi && data?.TRANTOPUP_SUCCESS !== undefined) {
      if (data?.TRANTOPUP_SUCCESS?.status == 'pending') {
        setSuccess_data('PANDING');
        setcheckTransapi(false);
        dispatch(
          balanceActions.TransStatustopup(
            data?.STK_DEPOSIT_SUCCESS?.data?.MerchantRequestID
          ) as any
        );
        setTimeout(() => {
          setcheckTransapi(true);
        }, 1500);
      } else if (data?.TRANTOPUP_SUCCESS?.status == 'failed') {
        setSuccess_data('FAILURE');
        setcheckTransapi(false);
      } else if (data?.TRANTOPUP_SUCCESS?.status == 'success') {
        setcheckTransapi(false);
        setOption(data?.TRANTOPUP_SUCCESS);
        setSuccess_data('SUCCESS');
        setTimeout(() => {
          setSuccess_data('');
          setShow(0)
          setSuccess_data_tan(true);
        }, 4000);
      }
    }
  }, [data, checkTransapi]);
  console.log(data, 'datadatadata');

  const Closepopup = () => {
    setSuccess_data('');
    setSuccess_data_tan(false);
    props.onClose();
  };
  useEffect(() => {
    if (!!props.currancyselect) {
      setSelectCurrencyPopup(props.currancyselect)
      setShow(2)
    } else {
      // setSelectCurrencyPopup
    }
  }, [props.currancyselect]);
  const onClose = () => {
    setShow(0);
    props.onClose(false);
  };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const handleClose = () => {
    onClose()
  };
  // const onSaveBank = () => {
  //   setSavePaymentDetailsRender(true);
  //   onClose()
  // };
  return (
    <>
      <Modal
        show={showModal == 1 ? true : false}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal"
        centered
        size="lg"
      >
        <ThemeProvider theme={theme}>
          <Box className="CloseContenar">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="heding_contean">
            <Typography>Select source account </Typography>
          </Box>

          <Modal.Body>
            {/* <div className="type-card-list">
            {countryList.map((item: any, index) => {
              return (
                <>
                
                  {!!user &&
                    user?.role_name &&
                    user?.role_name == "Director" ? (
                    <div
                      // id="type_card_id"
                      // className={`type-card-twoo ${selectedMethod === item.currency ? "active" : ""
                      //   }`}
                      style={selectedMethod == item.currency ? { background: "#fff", border: "1px solid #025041" } : { background: "#F2F8F7" }}
                      onClick={() => {
                        // props.onSave(item.currency)
                        setSelectCurrencyPopup(item.currency);
                        setShow(2);
                      }}
                    >
                      <div className={styles.currency_img}>
                        <img src={item.flag} />
                      </div>
                      <div className={styles.main_divtext}>
                        <Typography className={styles.currency_text}>
                          {item.currency}
                        </Typography>
                        <Typography className={styles.country_text}>
                          {item.name}
                        </Typography>
                        <Typography className={styles.Typography_clsamount}>
                          {item.currency} {!!item && item.total_balance
                            ? (item.total_balance / 100).toLocaleString(
                              'en',
                              { minimumFractionDigits: 2 }
                            )
                            : '0.00'}

                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ cursor: "no-drop" }}
                      id="type_card_id"
                      className={`type-card-twoo ${selectedMethod === item.currency ? "active" : ""
                        }`}
                    >
                      <div>
                        <p className="BOX_tital_styles">{item.currency}</p>
                        <span className="type-card__name">{item.name}</span>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div> */}

            <Grid container style={{ padding: "16px 0px" }} className={styles.grid_currency_box}>
              {/* {countryList?.map((element: any, index: number) => { */}
              {props.typeButton == "Send payments" ? <>

                {countryList?.length > 0 ? (
                  countryList?.filter((element: any) => element.isBalance).length > 0 ? (
                    countryList?.filter((element: any) => element.isBalance).map((element: any, index: number) => {
                      return (
                        <Grid item md={6} sm={12} xs={12} className={styles.grid_button_contean}>
                          {!!user &&
                            user?.role_name &&
                            user?.role_name == "Director" ? (
                            <Button style={selectedMethod == element.currency ? { background: "#fff", border: "1px solid #025041", padding: "12px 16px" } : { background: "#F2F8F7", padding: "12px 16px" }}
                              onClick={() => {
                                // props.setCurrancylist(element.currency)
                                setSelectCurrencyPopup(element.currency);
                                setShow(2);
                                setBalanceamount(element.total_balance / 100)
                              }}
                              className={styles.box_currency}>
                              <div className={styles.currency_img}>
                                <img src={element.flag} />
                              </div>
                              <div className={styles.main_divtext}>
                                <Typography className={styles.currency_text}>
                                  {element.currency}
                                </Typography>
                                <Typography className={styles.country_text}>
                                  {element.name}
                                </Typography>
                                <Typography className={styles.Typography_clsamount}>
                                  {element.currency} {!!element && element.total_balance
                                    ? (element.total_balance / 100).toLocaleString(
                                      'en',
                                      { minimumFractionDigits: 2 }
                                    )
                                    : '0.00'}

                                </Typography>
                              </div>
                            </Button>) : (
                            <Button
                              style={{ background: "#F2F8F7", cursor: "no-drop" }}
                              // style={{ cursor: "no-drop" }}
                              onClick={() => {
                                // props.setCurrancylist(element.currency)
                                //  setSelectCurrencyPopup(element.currency);
                                //  setShow(2);

                              }}
                              className={styles.box_currency}>
                              <div className={styles.currency_img}>
                                <img src={element.flag} />
                              </div>
                              <div className={styles.main_divtext}>
                                <Typography className={styles.currency_text}>
                                  {element.currency}
                                </Typography>
                                <Typography className={styles.country_text}>
                                  {element.name}
                                </Typography>
                                <Typography className={styles.Typography_clsamount}>
                                  {element.currency} {!!element && element.total_balance
                                    ? (element.total_balance / 100).toLocaleString(
                                      'en',
                                      { minimumFractionDigits: 2 }
                                    )
                                    : '0.00'}

                                </Typography>
                              </div>
                            </Button>
                          )}
                        </Grid>
                      );
                    })
                  ) : (
                    <div
                      style={{ marginLeft: "20px" }}
                    >
                      <Dashboard_nodata button="" Link={``} img="/uil_transaction.svg" text="" />
                      <Typography>
                        Insufficient balance in the currency accounts. Please top-up to proceed.
                      </Typography>
                    </div>
                  )
                ) : (
                  <div
                    style={{ marginLeft: "20px" }}
                  >
                    <Dashboard_nodata button="" Link={``} img="/uil_transaction.svg" text="" />
                    <Typography>
                      Insufficient balance in the currency accounts. Please top-up to proceed.
                    </Typography>
                  </div>
                )}
              </> :
                <>
                  {countryList?.map((element: any, index: number) => {
                    return (
                      <Grid item md={6} sm={12} xs={12} className={styles.grid_button_contean}>
                        {!!user &&
                          user?.role_name &&
                          user?.role_name == "Director" ? (
                          <Button style={selectedMethod == element.currency ? { background: "#fff", border: "1px solid #025041", padding: "12px 16px" } : { background: "#F2F8F7", padding: "12px 16px" }}
                            onClick={() => {
                              // props.setCurrancylist(element.currency)
                              setSelectCurrencyPopup(element.currency);
                              setShow(2);
                              setBalanceamount(element.total_balance / 100)
                            }}
                            className={styles.box_currency}>
                            <div className={styles.currency_img}>
                              <img src={element.flag} />
                            </div>
                            <div className={styles.main_divtext}>
                              <Typography className={styles.currency_text}>
                                {element.currency}
                              </Typography>
                              <Typography className={styles.country_text}>
                                {element.name}
                              </Typography>
                              <Typography className={styles.Typography_clsamount}>
                                {element.currency} {!!element && element.total_balance
                                  ? (element.total_balance / 100).toLocaleString(
                                    'en',
                                    { minimumFractionDigits: 2 }
                                  )
                                  : '0.00'}

                              </Typography>
                            </div>
                          </Button>) : (
                          <Button
                            style={{ background: "#F2F8F7", cursor: "no-drop" }}
                            // style={{ cursor: "no-drop" }}
                            onClick={() => {
                              // props.setCurrancylist(element.currency)
                              //  setSelectCurrencyPopup(element.currency);
                              //  setShow(2);

                            }}
                            className={styles.box_currency}>
                            <div className={styles.currency_img}>
                              <img src={element.flag} />
                            </div>
                            <div className={styles.main_divtext}>
                              <Typography className={styles.currency_text}>
                                {element.currency}
                              </Typography>
                              <Typography className={styles.country_text}>
                                {element.name}
                              </Typography>
                              <Typography className={styles.Typography_clsamount}>
                                {element.currency} {!!element && element.total_balance
                                  ? (element.total_balance / 100).toLocaleString(
                                    'en',
                                    { minimumFractionDigits: 2 }
                                  )
                                  : '0.00'}

                              </Typography>
                            </div>
                          </Button>
                        )}
                      </Grid>
                    );
                  })}
                </>
              }
              <Grid item md={6}>

              </Grid>
            </Grid>
          </Modal.Body>
        </ThemeProvider>
      </Modal>
      <Modal
        show={showModal == 2 ? true : false}
        onHide={() => {
          onClose();
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal"
        centered
        size="lg"
      >
        <ThemeProvider theme={theme}>
          <Box className="CloseContenar">
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="heding_contean">
            <Typography>How do you want to {props.typeButton == "Send payments" ? "pay" : "top up"}?</Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="selectBox"
              value={selectCurrencyPopup}
              onChange={(e: any) => {
                setSelectCurrencyPopup(e.target.value);
              }}
            >
              {Array.isArray(countryList) &&
                countryList.map((item: any, index) => {
                  return (
                    <MenuItem value={item.currency} key={index}>
                      <Box className="MenuItemBox" {...props}>
                        <img loading="lazy" width="25" src={item.flag} alt="" />
                        <p>{item.currency}</p>
                      </Box>
                    </MenuItem>
                  );
                })}
            </Select>
          </Box>
        </ThemeProvider>
        <Modal.Body>
          <div className="type-card-list">
            {(selectCurrencyPopup == "KES" || selectCurrencyPopup == "USD") && props.typeButton == "Send payments" ? (
              <div
                id="main_fast_buttun_data"
                className={`type-card-twoo ${selectedMethod === "Express Payment KES" ? "active" : ""
                  }`}
                onClick={() => {
                  if (selectCurrencyPopup == "KES") {
                    navigate("/payment-journey?type=" + "Express Payment KES", {
                      replace: true,
                      state: { BILLSBULK: "Express Payment KES" },
                    });
                  } else if (selectCurrencyPopup == "USD") {
                    navigate("/payment-journey?type=" + "Express Payment USD", {
                      replace: true,
                      state: { BILLSBULK: "Express Payment USD" },
                    });
                  }
                }}
              >
                <span id="sdFsfsfsfsfff" className="type-card__name">
                  Express Payment
                </span>
              </div>
            ) : (
              ""
            )}
            <div
              className={`type-card ${selectedMethod === "Dollar" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedMethod('Dollar');
                setShow(3);
              }}
            >
              <div>
                <DollerIcon />
                <span className="type-card__name">via Currency Conversion</span>
              </div>
              <div className="type-card__detail">
                {props.typeButton == "Send payments" ? "Convert other currency balance to make payment" :
                  "Convert other currency balance to top up this account"}
              </div>
            </div>
            {(selectCurrencyPopup == 'USD' || selectCurrencyPopup == 'KES') && props.typeButton == "Receive payments" ?
              <div
                className={`type-card ${selectedMethod === 'visa-card' ? 'active' : ''
                  }`}
                onClick={() => {
                  setSelectedMethod('visa-card');
                  setShow(5);
                }}
              >
                <div>
                  <VisaCardIcon />
                  <span className="type-card__name">via Bank Account</span>
                </div>
                <div className="type-card__detail">
                  Directly top up your balance using money from your bank
                </div>
              </div>
              : ""}
          </div>
          {selectCurrencyPopup == 'KES' && props.typeButton == "Receive payments" ?
            <div className="type-card-list">

              <div
                className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''
                  }`}
                onClick={() => {
                  setSelectedMethod('mobile')
                  setShow(4);
                }}
              >
                <div>
                  <MobileIcon />
                  <span className="type-card__name">via M-Pesa</span>
                </div>
                <div className="type-card__detail">
                  Fund your balance using M-Pesa
                </div>
              </div>
            </div> : ""}

        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        centered
        className="topup-modal-two"
        aria-labelledby="ModalHeader"
        backdrop="static"
        show={showModal == 3 ? true : false}
        onHide={onClose}
      >
        <Doller
          // select={}
          balanceamount={balanceamount}
          currancyDestination={selectCurrencyPopup}
          typeButton={props.typeButton}
          closeModel={onClose}
          onSaveBank={onSaveBank}
        />
      </Modal>
      <Modal
        centered
        className="topup-modal2"
        aria-labelledby="ModalHeader"
        backdrop="static"
        show={showModal == 4 ? true : false}
        onHide={() => {
          onClose()
        }}
      >
        {!success_data ? (
          <ThemeProvider theme={theme}>
            <Box className="CloseContenar">
              <IconButton onClick={() => {
                onClose()
              }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box style={{ padding: "10px 32px 0px 32px", borderBottom: "0px solid" }} className="heding_contean">
              <Typography>
                Top up via M-Pesa
              </Typography>
            </Box>
          </ThemeProvider>
        ) : (
          ''
        )}
        <Modal.Body>
          {success_data == 'PANDING' ? (
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                top: 20,
                bottom: 0,
                right: 0,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
              }}
            ></div>
          ) : success_data == 'SUCCESS' && success_data_tan == false ? (
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
              }}
            >
              <img
                width={75}
                height={75}
                src="/Niobi Loading Animation (1).gif"
              />
            </div>
          ) : success_data == 'FAILURE' ? (
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
              }}
            >
              <img
                width={75}
                height={75}
                src="/Niobi Loading Animation-Check.gif"
              />
            </div>
          ) : (
            ''
          )}
          {!success_data ? (
            <>
              {/* <ThemeProvider theme={theme}>
                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                  style={{ marginTop: '15px' }}
                  sx={{
                    '& .MuiButtonBase-root': {
                      padding: '0px 0px 0px 5px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      '& .MuiAccordionSummary-content': {
                        margin: '10px 0px',
                      },
                    }}
                  >
                    <p className="text_new_heding_data">Use Paybill</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="topup-modal2__description">
                      <p>
                        Top up your balance via M-Pesa using the following
                        details:
                      </p>
                    </div>

                    <div className="topup-modal2__table">
                      <div className="d-flex b-bot">
                        <div className="left">Paybill No.</div>
                        <div className="right">
                          {!!topupDetails
                            ? topupDetails.paybill_number
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="left">Niobi Account No.</div>
                        <div className="right">
                          {!!topupDetails
                            ? topupDetails.account_number
                            : ''}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </ThemeProvider> */}
              <>
                <ThemeProvider theme={theme}>
                  <Accordion
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                    style={{ marginTop: '15px' }}
                    sx={{
                      '& .MuiButtonBase-root': {
                        padding: '0px 0px 0px 5px',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          margin: '10px 0px',
                        },
                      }}
                    >
                      <p className="text_new_heding_data">
                        Use Instant Payment
                      </p>{' '}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <p className="discrip_the_ahhahas">
                          Dear Customer,
                        </p>
                        <p className="discrip_the_ahhahas">
                          You will shortly receive a prompt on your phone to enter your mobile money PIN to complete the payment. Please ensure your phone is on and unlocked to complete the process.

                        </p>

                        <div className="company-details-container__wrapper">
                          <div className="input-form-wrapper">
                            <label>Mobile</label>
                            <PhoneInput
                              inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true,
                              }}
                              country={'ke'}
                              value={pohon_data}
                              onChange={(
                                value,
                                country,
                                e,
                                formattedValue
                              ) => {

                                console.log(value);
                                console.log(value, e, 'aahauyaahaha');
                                setPhone(value);
                                setPhoneValidation(true);
                              }}
                              inputClass={'inputBx-mobile readonly'}
                            />
                          {getEnvironment() === 'production' ?
                                    <>
                                      {phone_validation == true ? (
                                        !!pohon_data ? (
                                          ""
                                        ) : (
                                          <div className="red invalid-feedback d-block">
                                            Please enter the mobile number
                                          </div>
                                        )
                                      ) : (
                                        <></>

                                      )}
                                    </> :getEnvironment() === 'sandbox'? <>
                                      {pohon_data?.length < 5&&data_stastes == true ?
                                        <div className="red invalid-feedback d-block">
                                          Please enter the mobile number
                                        </div> : ""}
                                    </>:""}


                            <div className="input-form-wrapper">
                              <label>Amount</label>
                              <input
                                type="text"
                                id="bg_clor_add_input"
                                className="text-field"
                                value={email_data}
                                onChange={(e: any) => {
                                  const newValue = e.target.value;
                                  if (/^\d*\.?\d*$/.test(newValue)) {
                                    setEmail_data(newValue);
                                  } else {
                                    <p className="err_calss_display">
                                      Please enter a valid amount.
                                    </p>
                                  }
                                  //setEmail_data(e.target.value);
                                }}
                              />
                            </div>
                            {data_stastes == true && email_data == '' ? (
                              <p className="err_calss_display">
                                Please enter the amount.
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>

                      {!!pohon_data && !!email_data ? (
                        <Button
                          id="fast_go_button"
                          className="balance__save-btn"
                          // disabled={pohon_data.length != 12}
                          onClick={() => {
                            if (getEnvironment() === 'production' && pohon_data.length == 12) {
                              handsubmit();
                              setStast_data(true);
                            } else if (getEnvironment() === 'sandbox' && pohon_data.length > 4) {
                              handsubmit();
                              setStast_data(true);
                            }
                            // handsubmit();
                            // setStast_data(true);
                          }}
                        >
                          Instant Payment
                        </Button>
                      ) : (
                        <Button
                          id="fast_go_button"
                          className="balance__save-btn"
                          onClick={() => {
                            setStast_data(true);
                          }}
                        >
                          Instant Payment
                        </Button>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </ThemeProvider>
              </>
            </>
          ) : (
            <>
              {success_data == 'PANDING' ? (
                <div className="div_pading_data">
                  <img src="/logo 3.svg" />
                  <p className="des_data">
                    Kindly check your phone and{' '}
                    <span className="helite_text">
                      enter your Mpesa PIN
                    </span>{' '}
                    to complete the payment.
                  </p>
                  {/* <Loader_icon /> */}
                  <img
                    width={45}
                    height={45}
                    src="/Niobi Loading Animation-Cross.gif"
                  />
                </div>
              ) : success_data == 'SUCCESS' &&
                success_data_tan == false ? (
                <div className="contenar_main_text_line">
                  <Success_icon />
                  <p className="classname_text_key_minnn">
                    Payment Successful
                  </p>
                </div>
              ) : success_data == 'FAILURE' ? (
                <div className="contenar_main_text_line">
                  <img src="/image 4.png" />
                  <p className="text_err_contenarr_minn">
                    Payment Failed
                  </p>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </Modal.Body>

        <div className="trans-detail-container__btn-row">
          {!success_data ? "" : success_data == 'PANDING' ? (
            ''
          ) : (
            <Button
              className="trans-detail-container__btn-row__green"
              onClick={() => {
                onClose()
              }}
            >
              {success_data == 'SUCCESS' ? 'Close' : 'Retry'}
            </Button>
          )}
        </div>
      </Modal>
      <Modal
        centered
        className="topup-modal3"
        aria-labelledby="ModalHeader"
        backdrop="static"
        show={success_data_tan}
        style={{ padding: '0px', margin: '0px' }}
        onHide={() => {
          onClose()
        }}
      >
        <TransactionDetails
          ClosePOPUP={Closepopup}
          data={option}
          type={option}
        />
      </Modal>
      <Modal
        size="lg"
        centered
        className="topup-modal2 bd-example-modal-lg"
        aria-labelledby="ModalHeader"
        backdrop="static"
        show={showModal == 5 ? true : false}
        onHide={() => {
          onClose()
        }}
      >
        <BankVia
          selectedMethod_two={selectCurrencyPopup}
          closeModel={handleClose}
          onSaveBank={onSaveBank}
        />
      </Modal>
      <>
        <Modal
          centered
          className="topup-modal2"
          aria-labelledby="ModalHeader"
          backdrop="static"
          show={showModal == 6 ? true : false}
          onHide={() => {
            onClose()
          }}
        >
          <Modal.Title className="topup-modal2__heading">
            Transaction Summary
          </Modal.Title>
          <Modal.Body>
            <div className="topup-modal2__description">
              <p>
                <img
                  src="./assets/DB.svg"
                  alt="DB"
                  style={{ marginRight: "10px" }}
                />
                Payment Submitted
              </p>
            </div>
            <div className="topup-modal2__table">
              <div className="d-flex b-bot">
                <div className="left">Amount</div>
                <div className="right">
                  {!!bankResponse
                    ? selectCurrencyPopup + " " + bankResponse.amount
                    : ""}
                </div>
              </div>
              <div className="d-flex b-bot ">
                <div className="left">Payment Channel</div>
                <div className="right">
                  {!!bankResponse && bankResponse.payment_method.key
                    ? bankResponse.payment_method.key
                    : ""}
                </div>
              </div>

              <div className="d-flex b-bot ">
                <div className="left">Transaction Date</div>
                <div className="right">
                  {!!bankResponse && bankResponse.transaction_date
                    ? moment(bankResponse.transaction_date).format(
                      "D MMMM YYYY"
                    )
                    : ""}
                </div>
              </div>

              <div className="d-flex b-bot">
                <div className="left">Payment Reference</div>
                <div className="right">
                  {!!bankResponse ? bankResponse.payment_reference : ""}
                </div>
              </div>
              {!!bankResponse && !!bankResponse.url && (
                <div className="d-flex b-bot">
                  <div className="left">Support document upload</div>
                  <div className="right">
                    {!!bankResponse && !!bankResponse.file_name
                      ? bankResponse.file_name
                      : "-"}
                  </div>
                </div>
              )}
              <div className="d-flex">
                <div className="left">Status</div>
                <div className="right">
                  {!!bankResponse
                    ? bankResponse.status == "success"
                      ? "Success"
                      : ""
                    : ""}
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="topup-modal2__button">
            <input
              type="button"
              value="Return to account"
              className="green-btn"
              onClick={() => {
                onClose()
              }}
            />
          </div>
        </Modal>
      </>
    </>
  );
};
export default Select_source_account;
interface CountryType {
  label: string;
  img: string;
}
const MobileIcon = () => (
  <svg
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 19H9V18H5V19ZM2 22C1.45 22 0.979333 21.8043 0.588 21.413C0.196 21.021 0 20.55 0 20V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H12C12.55 0 13.021 0.195667 13.413 0.587C13.8043 0.979 14 1.45 14 2V20C14 20.55 13.8043 21.021 13.413 21.413C13.021 21.8043 12.55 22 12 22H2ZM2 17V20H12V17H2ZM2 15H12V5H2V15ZM2 3H12V2H2V3ZM2 17V20V17ZM2 3V2V3Z"
      fill="#025041"
    />
  </svg>
);
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries: readonly CountryType[] = [
  { label: "KES", img: "/kenya-flag.png" },
  {
    label: "USD",
    img: "/kenya-flag.png",
  },
];
const DollerIcon: any = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
  >
    <path
      d="M11.3333 12.3332V8.99984L15.5 13.1665L11.3333 17.3332V13.9998H1.33333V12.3332H11.3333ZM4.66667 0.666504V3.999L14.6667 3.99984V5.6665H4.66667V8.99984L0.5 4.83317L4.66667 0.666504Z"
      fill="#025041"
    />
  </svg>
);
const Success_icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
  >
    <g clip-path="url(#clip0_7237_14869)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28ZM26.9021 39.984L43.0227 19.8315L40.1107 17.5019L26.3645 34.6789L16.628 26.5664L14.2387 29.4336L26.9021 39.9877V39.984Z"
        fill="#025041"
      />
    </g>
    <defs>
      <clipPath id="clip0_7237_14869">
        <rect width="56" height="56" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
const VisaCardIcon = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
      fill="#025041"
    />
  </svg>
);