import { FieldAlreadyExistsError } from 'pdf-lib';
import { alertActions } from '.'
import { Unauthorized } from '../_constants/ErrorMessage';
import { paymentJourneyConstants } from "../_constants/paymentJourney.constants";
import { paymentJourneyService } from "../_services/paymentJourney.service";
import toast from 'react-hot-toast';

export const paymentJourneyActions = {
    getFees,
    getFeesNew,
    getInveact,
    getPesaBankList,
    AddBankDisbursement,
    Express_Payment_actions,
    AddBankDisbursement_experes,
    Create_integration_active,
    getIntegration_active,
    Create_credentials_active,
    getIntegration_apikey_active,
    credentials_delete_active,
    getdurationlist_active,
    Create_budget_active,
    budget_list_data,
    budget_viwe_list,
    budget_delete_active,
    edit_budget_active,
    getBulk_payment,
    getbulk_payments_status_active,
    getSave_data,
    getPaybillNew,
    get_accounting_types_active,
    create_connection,
    get_connections,
    disable_connection,
    getPaymentLinkByShortcode,
    CreatePayViaLink,
    TransStatusLink,
    TransLinkPdf,
    invoicesend,
    invoicesendreceiver,
    Bankdetails,
    PaymentLinkApiList,
    paymentLinkApiCreate,
    getShareablePdfLink,
    PaymentLinkApiDeleteActive,
    PaymentLinkApiUpdateActive,
    StanbicVerifyAccount,
    getCountries,
    getCountriesDetails,
    getCurrencies,
    getCurrenciesV2,
    getCountriesByCurrency,
    AddBankExperesUsd,
    ChackBalance,
    getCountriesBalances,
    checkTransactionType,
    NiobiSignatureactions,
    getCountriesV2,
    ChackBalancePaymentLink,
    getValidateAccount,
    getAllCurrenciesDetails
}
function getAllCurrenciesDetails() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getAllCurrenciesDetails()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETALL_CURRENCIES_DETAILS_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETALL_CURRENCIES_DETAILS_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETALL_CURRENCIES_DETAILS_FAILURE } }
}
function getFees(amount: any, payment_method: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getFees(amount, payment_method)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETFEES_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETFEES_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETFEES_FAILURE } }
}
function getValidateAccount(account_number: any,method:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getValidateAccount(account_number,method)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response) {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETVALIDATE_ACCOUNT_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETVALIDATE_ACCOUNT_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETVALIDATE_ACCOUNT_FAILURE } }
}
function checkTransactionType(amount: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.checkTransactionType(amount)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response) {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.CHECK_TRANSACTION_TYPE_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.CHECK_TRANSACTION_TYPE_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.CHECK_TRANSACTION_TYPE_FAILURE } }
}
function ChackBalance(bodyData:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.ChackBalance(bodyData)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success == true) {
                    dispatch(success(response))
                } else {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.CHACKBALANCE_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.CHACKBALANCE_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.CHACKBALANCE_FAILURE } }
}
function ChackBalancePaymentLink(country_id: any, currency: any, amount: any,environment:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.ChackBalancePaymentLink(country_id, currency, amount,environment)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success == true) {
                    dispatch(success(response))
                } else {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.CHACKBALANCE_PAYMENT_LINK_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.CHACKBALANCE_PAYMENT_LINK_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.CHACKBALANCE_PAYMENT_LINK_FAILURE } }
}
function getShareablePdfLink(ref: any, id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getShareablePdfLink(ref, id)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                    console.log('Response Fees Success: ', response)
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETSHAREABLEPDFLINK_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETSHAREABLEPDFLINK_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETSHAREABLEPDFLINK_FAILURE } }
}
function getInveact(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getInvite(datalist)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    toast.success(response.message)
                    dispatch(success(response.data))
                } else {
                    toast.error(response.message)
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.INVITE_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.INVITE_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.INVITE_FAILURE } }
}
function getSave_data(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getSave_data(datalist)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    toast.success(response.message)
                    dispatch(success(response.data))
                } else {
                    toast.error(response.message)
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.SAVE_DATA_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.SAVE_DATA_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.SAVE_DATA_FAILURE } }
}
function getBulk_payment(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getBulk_payment(datalist)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success == true) {
                    dispatch(success(response.data))
                    setTimeout(() => {
                        window.location.href = '/approvals/approve_payment'

                    }, 1200)
                } else {
                    toast.error(response.message)
                    dispatch(success(response))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GET_BULKPAYMENT_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GET_BULKPAYMENT_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GET_BULKPAYMENT_FAILURE } }
}
function getFeesNew(amount: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getFeesNew(amount)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETNEWFEES_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETNEWFEES_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETNEWFEES_FAILURE } }
}
function getCountries(id:any,environment:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getCountries(id,environment)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETCOUNTRIES_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETCOUNTRIES_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETCOUNTRIES_FAILURE } }
}
function getCountriesV2() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getCountriesV2()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETCOUNTRIES_V2_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETCOUNTRIES_V2_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETCOUNTRIES_V2_FAILURE } }
}
function getCountriesBalances() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getCountriesBalances()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETCOUNTRIES_BALANCES_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETCOUNTRIES_BALANCES_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETCOUNTRIES_BALANCES_FAILURE } }
}
function getCountriesDetails(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getCountriesDetails(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETCOUNTRIES_DETAILS_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETCOUNTRIES_DETAILS_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETCOUNTRIES_DETAILS_FAILURE, s } }
}
function getCurrencies(Id:any,environment:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getCurrencies(Id,environment)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETCURRENCIES_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETCURRENCIES_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETCURRENCIES_FAILURE, s } }
}
function getCurrenciesV2() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getCurrenciesV2()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETCURRENCIES_V2_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETCURRENCIES_V2_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETCURRENCIES_V2_FAILURE, s } }
}
function getCountriesByCurrency(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getCountriesByCurrency(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETCOUNTRIES_BY_CURRENCY_FAILURE, s } }
}
function getPaybillNew(amount: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getPayBILLNew(amount)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response) {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GET_PAYBILL_NEW_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GET_PAYBILL_NEW_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GET_PAYBILL_NEW_FAILURE } }
}
function StanbicVerifyAccount(amount: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.StanbicVerifyAccount(amount)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response) {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.STANBIC_VERIFY_ACCOUNT_FAILURE } }
}
function getPesaBankList() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getPesaBankList()
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETPESABANKLIST_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETPESABANKLIST_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETPESABANKLIST_FAILURE } }
}
function getIntegration_active() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getIntegration()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETINTEGRATION_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETINTEGRATION_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETINTEGRATION_FAILURE } }
}
function get_accounting_types_active() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.get_accounting_types()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GET_ACCOUNTING_TYPE_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GET_ACCOUNTING_TYPE_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GET_ACCOUNTING_TYPE_FAILURE } }
}
function get_connections() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.get_connections()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GET_CONNECTION_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GET_CONNECTION_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GET_CONNECTION_FAILURE } }
}
function getdurationlist_active() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getdurationlist()
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETDURATIONLIST_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETDURATIONLIST_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETDURATIONLIST_FAILURE } }
}
function getIntegration_apikey_active(data: any,setaddexpressSuccrender:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.getintegration_apikey(data)
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                    setaddexpressSuccrender(true)
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETINTEGRATION_APIKEY_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETINTEGRATION_APIKEY_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETINTEGRATION_APIKEY_FAILURE } }
}
function credentials_delete_active(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.credentials_delete(data)
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.DELETE_CREDENTIALS_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.DELETE_CREDENTIALS_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.DELETE_CREDENTIALS_FAILURE } }
}
function budget_delete_active(id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.budget_delete_servicers(id)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                    window.location.href = "/budget"
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.DELETE_BUDGET_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.DELETE_BUDGET_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.DELETE_BUDGET_FAILURE } }
}
function PaymentLinkApiDeleteActive(id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.PaymentLinkApiDeleteServicers(id)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    toast.success(response.message)
                    dispatch(alertActions.success('Payment link deleted successfully'))
                    dispatch(success(response.message))
                    window.location.href = "/settings/Payment_Link"
                } else {
                    toast.error(response.data.message)
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.DELETE_PAYMENT_LINK_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.DELETE_PAYMENT_LINK_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.DELETE_PAYMENT_LINK_FAILURE } }
}
function budget_list_data(data: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.budget_list_data_service(data)
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                } else {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.LIST_BUDGET_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.LIST_BUDGET_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.LIST_BUDGET_FAILURE } }
}
function budget_viwe_list(data: any, setrender?: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        paymentJourneyService.budget_viwe_list_service(data)
            .then(data => {

                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                    if (typeof setrender == 'function') {
                        setrender(true)
                    }
                } else {
                    if (typeof setrender == 'function') {
                        setrender(true)
                    }
                    dispatch(alertActions.success('OTP verified successful'))
                    dispatch(success(response.data))
                }

            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: paymentJourneyConstants.GETBUDGET_LIST_REQUEST } }
    function success(data: any) { return { type: paymentJourneyConstants.GETBUDGET_LIST_SUCCESS, data } }
    function failure(s: string) { return { type: paymentJourneyConstants.GETBUDGET_LIST_FAILURE } }
}
function AddBankDisbursement(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {

        dispatch(request(datalist))

        paymentJourneyService.AddBankDisbursement(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message, { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_SUCCESS, data } }
    function failure(error: any) { return { type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_FAILURE, error } }
}
function Create_budget_active(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {

        dispatch(request(datalist))
        paymentJourneyService.Create_budget(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    toast.success(response.message, { duration: 8000 });
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message, { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.CREATE_BUDGET_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.CREATE_BUDGET_SUCCESS, data } }
    function failure(data: any) { return { type: paymentJourneyConstants.CREATE_BUDGET_FAILURE, data } }
}
function edit_budget_active(datalist: any, id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.edit_budget(datalist, id)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    toast.success(response.message, { duration: 8000 });
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message, { duration: 8000 });
                }
            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.EDIT_BUDGET_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.EDIT_BUDGET_SUCCESS, data } }
    function failure(data: any) { return { type: paymentJourneyConstants.EDIT_BUDGET_FAILURE, data } }
}
function PaymentLinkApiUpdateActive(datalist: any, id: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.PaymentLinkApiUpdateActive(datalist, id)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    toast.success(response.message, { duration: 8000 });
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    dispatch(success(response))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message || 'Payment link failed to update.', { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.PAYMENTLINKUPDATE_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.PAYMENTLINKUPDATE_SUCCESS, data } }
    function failure(data: any) { return { type: paymentJourneyConstants.PAYMENTLINKUPDATE_FAILURE, data } }
}
function Create_integration_active(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.Create_integration(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    dispatch(success(response.data))
                    window.location.href = '/settings/apikey'
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message, { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.CREATE_INTEGRATION_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.CREATE_INTEGRATION_SUCCESS, data } }
    function failure(data: any) { return { type: paymentJourneyConstants.CREATE_INTEGRATION_FAILURE, data } }
}
function getbulk_payments_status_active(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.getbulk_payments_status_active(datalist)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    dispatch(success(response))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message, { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_SUCCESS, data } }
    function failure(error: any) { return { type: paymentJourneyConstants.GET_BULK_PAYMENTS_STATUS_FAILURE, error } }
}
function Create_credentials_active(datalist: any,setaddexpressSuccrender:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.Create_credentials(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    dispatch(success(response.data))
                    setaddexpressSuccrender(true)
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message, { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.CREATE_CREDENTIALS_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.CREATE_CREDENTIALS_SUCCESS, data } }
    function failure(data: any) { return { type: paymentJourneyConstants.CREATE_CREDENTIALS_FAILURE, data } }
}
function AddBankDisbursement_experes(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.AddBankDisbursement_experes(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message, { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_SUCCESS, data } }
    function failure(error: any) { return { type: paymentJourneyConstants.ADDBANK_DESBURSEMENT_EXPERES_FAILURE, error } }
}
function AddBankExperesUsd(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.AddBankExperesUsd(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    console.log(response.data, "response.data");

                    dispatch(success(response.data))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    toast.error(response.message, { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.ADDBANK_EXPERES_USD_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.ADDBANK_EXPERES_USD_SUCCESS, data } }
    function failure(error: any) { return { type: paymentJourneyConstants.ADDBANK_EXPERES_USD_FAILURE, error } }
}
function NiobiSignatureactions(req: any, setRenderApi: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(req))
        paymentJourneyService.NiobiSignatureactions(req)
            .then(data => {
                // dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(alertActions.success('Add Bank disbursemennt'))
                    console.log(response, "response.data");
                    setRenderApi(true)
                    dispatch(success(response))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                    setRenderApi(false)
                    toast.error(response.message, { duration: 8000 });
                }

            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.NIOBI_SIGNATURE_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.NIOBI_SIGNATURE_SUCCESS, data } }
    function failure(error: any) { return { type: paymentJourneyConstants.NIOBI_SIGNATURE_FAILURE, error } }
}
function create_connection(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.create_connection(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(success(response.data))
                }
            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.CREATE_CONNECTION_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.CREATE_CONNECTION_SUCCESS, data } }
    function failure(data: any) { return { type: paymentJourneyConstants.CREATE_CONNECTION_FAILURE, data } }
}
function disable_connection(datalist: any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))
        paymentJourneyService.disable_connection(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data))
                } else {
                    dispatch(success(response.data))
                }
            },
                error => {
                    toast.error(Unauthorized, { duration: 8000 })
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(data: any) { return { type: paymentJourneyConstants.DISABLE_CONNECTION_REQUEST, data } }
    function success(data: any) { return { type: paymentJourneyConstants.DISABLE_CONNECTION_SUCCESS, data } }
    function failure(data: any) { return { type: paymentJourneyConstants.DISABLE_CONNECTION_FAILURE, data } }
}
function Express_Payment_actions(req: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))
        paymentJourneyService.Express_Payment_Service(req)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 });
                    }
                },
                error => {
                    toast.error(Unauthorized, { duration: 8000 });
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.EXPRESSPAYMENT_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.EXPRESSPAYMENT_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.EXPRESSPAYMENT_FAILURE, error } }
}
// function NiobiSignatureactions(req: any,setRenderApi:any) {
//     return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
//         dispatch(request(req))
//         paymentJourneyService.NiobiSignatureactions(req)
//             .then(response => {
//                 return response.json()
//             })
//             .then(
//                 otp => {
//                     console.log(otp.success,otp,"otp.success");

//                     if (otp.success) {
//                         dispatch(success(otp));
//                         setRenderApi(true)
//                         dispatch(alertActions.success('Express payment Request successful'))
//                     } else {
//                         setRenderApi(false)
//                         dispatch(failure(otp.message));
//                         dispatch(alertActions.error(otp.message));
//                         toast.error(otp.message, { duration: 8000 });
//                     }
//                 },
//                 error => {
//                     toast.error(Unauthorized, { duration: 8000 });
//                     dispatch(failure(error.toString()))
//                     dispatch(alertActions.error(error.toString()))
//                 }
//             )
//     }

//     function request(otp: any) { return { type: paymentJourneyConstants.NIOBI_SIGNATURE_REQUEST, otp } }
//     function success(otp: any) { return { type: paymentJourneyConstants.NIOBI_SIGNATURE_SUCCESS, otp } }
//     function failure(error: any) { return { type: paymentJourneyConstants.NIOBI_SIGNATURE_FAILURE, error } }
// }
function getPaymentLinkByShortcode(req: any, environment: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))
        paymentJourneyService.getPaymentLink_shortcode(req, environment)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 });
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.PAYMENTLINKSHORTCODE_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.PAYMENTLINKSHORTCODE_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.PAYMENTLINKSHORTCODE_FAILURE, error } }
}
function CreatePayViaLink(req: any, environment: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))
        paymentJourneyService.CreatePayViaLink(req, environment)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 });
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.PAYLINK_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.PAYLINK_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.PAYLINK_FAILURE, error } }
}
function TransStatusLink(req: any, environment: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))
        paymentJourneyService.TransStatusLink(req, environment)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 });
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.TRANSPAYLINK_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.TRANSPAYLINK_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.TRANSPAYLINK_FAILURE, error } }
}
function PaymentLinkApiList(req: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))

        paymentJourneyService.PaymentLinkApiList(req)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 });
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.PAYMENTLINKAPILIST_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.PAYMENTLINKAPILIST_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.PAYMENTLINKAPILIST_FAILURE, error } }
}
function TransLinkPdf(req: any, data: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))

        paymentJourneyService.TransLinkPdf(req, data)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 });
                    }
                },
                error => {
                    ;
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.TRANSPAYLINK_PDF_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.TRANSPAYLINK_PDF_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.TRANSPAYLINK_PDF_FAILURE, error } }
}
function invoicesend(req: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))

        paymentJourneyService.invoicesend(req)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.INVOICE_SENDTOME_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.INVOICE_SENDTOME_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.INVOICE_SENDTOME_FAILURE, error } }
}
function paymentLinkApiCreate(req: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))
        paymentJourneyService.paymentLinkApiCreate(req)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success('Payment link creation successful'));
                        toast.success(otp.message, { duration: 8000 });
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 })
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.PAYMENT_LINK_API_CREATE_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.PAYMENT_LINK_API_CREATE_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.PAYMENT_LINK_API_CREATE_FAILURE, error } }
}
function invoicesendreceiver(req: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))

        paymentJourneyService.invoicesendreceiver(req)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 });
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.INVOICE_SENDTO_RECEIVER_FAILURE, error } }
}
function Bankdetails(req: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))

        paymentJourneyService.Bankdetails(req)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp.data));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message, { duration: 8000 });
                    }
                },
                error => {

                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: paymentJourneyConstants.BANK_DETELS_REQUEST, otp } }
    function success(otp: any) { return { type: paymentJourneyConstants.BANK_DETELS_SUCCESS, otp } }
    function failure(error: any) { return { type: paymentJourneyConstants.BANK_DETELS_FAILURE, error } }
}